/**This is the main component that should be called from index */

import { loadTheme, Stack } from 'office-ui-fabric-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { COVIDFormLookup } from '../model/DBModel';
import COVIDFormsService from '../service/HDFApp.service';
import { useQuery } from '../utils/HDFHooks';
import { ratTitle } from '../utils/Constants';
import HealthForm from './HealthForm';
import Login from './Login';
import LoadingShimmer from './subComponents/Loading';
import UserRegistration from './UserRegistration';
import HDFUserContextWrapper from '../utils/HDFUserContextWrapper';
import UserMain from './UserMain';
import CheckInHistory from './History';
import ErrorBoundaryComp from './InternalErrorBoundary';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FormUrlParams } from '../model/CovidForm';
const debug = require('debug');
const log = debug('hdf:app');
const lerror = debug('hdf:errors');

loadTheme({
	fonts: {
		small: {
			fontSize: '14px'
		},
		medium: {
			fontSize: '16px'
		},
		large: {
			fontSize: '22px',
			fontWeight: '600'
		},
		xLarge: {
			fontSize: '24px',
			fontWeight: '600'
		}
	}
});

const App = (props: any) => {
	const { rat: rapidAntigenTestingUrl } = useQuery<FormUrlParams>();
	const isRAT: boolean = useMemo(() => rapidAntigenTestingUrl !== undefined && rapidAntigenTestingUrl !== null && rapidAntigenTestingUrl.length > 0, [rapidAntigenTestingUrl]);
	let hdfService: COVIDFormsService = useMemo(() => new COVIDFormsService(), []);
	const [formConfig, setFormConfig] = useState<COVIDFormLookup | undefined>();
	const appInsights = useAppInsightsContext();

	useEffect(() => {
		hdfService
			.getLookupForForm(COVIDFormsService.formNameGlb, window.location.hostname || window.location.host)
			.then((lookupdata: COVIDFormLookup) => {
				log('Config loaded');
				setFormConfig(lookupdata);
			})
			.catch(error => {
				lerror(error);
				appInsights.trackException({ exception: new Error(error) });

			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{formConfig === undefined ? (
				<LoadingShimmer />
			) : (
				<HDFUserContextWrapper>
					<Stack tokens={{ childrenGap: 8 }} key="hdf-main-form" id="hdf-main-form" verticalFill={true} verticalAlign="center">
						<Stack
							tokens={{ childrenGap: 10 }}
							styles={{
								root: {
									paddingBottom: 10
								}
							}}>
							<div style={{ maxWidth: 160 }}>
								<img alt="cpblogo" style={{ width: '100%' }} src={formConfig?.formLogoUrl}></img>
							</div>
							<div className="formTitle">{isRAT ? ratTitle : formConfig.formTitle}</div>
						</Stack>
						<ErrorBoundaryComp appInsightsContext={appInsights}>
							<Switch>
								<Route exact path="/" component={Login} />
								<Route exact path="/login" component={Login} />

								<Route exact path="/form" children={({ match }) => <HealthForm isRAT={isRAT} />} />
								<Route path="/new" exact component={UserRegistration} />
								<Route path="/main" component={UserMain} />
								<Route path="/history" component={CheckInHistory} />
							</Switch>
						</ErrorBoundaryComp>
					</Stack>
				</HDFUserContextWrapper>
			)}
		</>
	);
};
export default App;
