import moment from 'moment';
import { userCacheKey } from '../utils/Constants';
const lscache = require('lscache');
export type EvidenceType = "vaccination"|"double_vaccination"|"booster_vaccination"|"exemption"|"testresult"|"residence"|"permit"
export type EvidenceFileType = "image"|"pdf";
export const pdfMimeType = "application/pdf";

export interface HDFUserEvidenceRequest{
	mobile:string;
	file:string;
	type:EvidenceType;
	filetype:EvidenceFileType;
}

export interface HDFUserEvidence{
    id:string;
    type:EvidenceType;
    uploadedDate: string;
	filetype:EvidenceFileType;
}

export default class HDFUser {
	id?: string;
	firstname?: string;
	lastname?: string;
	staffType?: string;
	employer?: string;
	mobile?: string;
	email?:string;
    company: string;
    evidences:HDFUserEvidence[];

    valid:boolean;
	constructor() {
        this.valid = false;
        this.company = "cpb";
		this.evidences = [];
	}

	public static GetUserFromCache = (): HDFUser => {
		const cachedUser: any = lscache.get(userCacheKey);
		if (cachedUser && cachedUser.mobile) {
			let u:HDFUser = {...cachedUser};
			u.firstname = cachedUser.firstname.replace(/[^\x20-\x7E]+/g, '');
			u.lastname = cachedUser.lastname.replace(/[^\x20-\x7E]+/g, '');
			u.staffType = cachedUser.staffType.replace(/[^\x20-\x7E]+/g, '');
			u.mobile = cachedUser.mobile.replace(/[^\x20-\x7E]+/g, '');
			u.email = cachedUser.email ? cachedUser.email.replace(/[^\x20-\x7E]+/g, '') : '';

			if (cachedUser.employer) u.employer = cachedUser.employer.replace(/[^\x20-\x7E]+/g, '');

			if (cachedUser.mobile) u.id = cachedUser.mobile.replace(/[^\x20-\x7E]+/g, '');

			if (u.evidences && u.evidences.length > 0){
				const expTestResIdx = u.evidences.findIndex(u => moment(u.uploadedDate).isBefore(moment().subtract(72, 'hours')));

				if (expTestResIdx > -1){
					u.evidences = [...u.evidences.filter( (o,idx) => idx !== expTestResIdx)];
					HDFUser.SaveUserInCache(u);
				}
			}

            return u;
		}

		return new HDFUser();
	};

    public static SaveUserInCache = (user:HDFUser) => {
		const cachedUser: any = lscache.get(userCacheKey);

        if (!user.id) user.id = user.mobile;
		if (cachedUser) {
			lscache.set(userCacheKey,{
                ...cachedUser,
                ...user
            }, process.env.REACT_APP_CACHEEXPMINS);

		}else{
            lscache.set(userCacheKey,user, process.env.REACT_APP_CACHEEXPMINS);
        }
	};
}
