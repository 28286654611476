import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, DefaultButton, Label, Link, PrimaryButton, ProgressIndicator, Separator, Stack } from 'office-ui-fabric-react';
import { FormikTextField } from 'formik-office-ui-fabric-react';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { buttonStyle, textBoxPinCode } from '../style/Styles';
import { AppInsEvents, ausMobilePattern, mobileValidationError, _getErrorMessageForMobile } from '../utils/Constants';
import { useHistory, useLocation } from 'react-router-dom';
import COVIDFormsService from '../service/HDFApp.service';
import { HDFUserContext } from '../utils/HDFUserContextWrapper';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import HDFUser from '../model/HDFUser';
import { useQuery } from '../utils/HDFHooks';
import { FormUrlParams } from '../model/CovidForm';
const lscache = require('lscache');

const debug = require('debug');
const log = debug('hdf:login');
const lerror = debug('hdf:errors');

interface LoginForm {
	mobile: string;
	pin: string;
}

const schema = yup.object().shape({
	mobile: yup.string().matches(ausMobilePattern, mobileValidationError).required('Mobile number is required'),
	pin: yup.string().required('PIN is required').length(6, 'Your PIN must be 6 digits')
});

const rememberCacheKey:string = "remember-on-login";

const Login = (props: any) => {
	let hdfService: COVIDFormsService = useMemo(() => new COVIDFormsService(), []);
	let history = useHistory();
	let location = useLocation();
	const urlParams = useQuery<FormUrlParams>();

	const appInsights = useAppInsightsContext();

	const { currentUser, setCurrentUser } = React.useContext(HDFUserContext);

	const [error, setError] = useState<boolean>(false);

	const [rememberUser, setRememberUser] = useState<boolean>(false);
	const usr = HDFUser.GetUserFromCache();

	return (
		<Formik
			initialValues={{ mobile: currentUser ? currentUser.mobile! : (usr && usr.mobile ? usr.mobile : ''), pin: '' }}
			onSubmit={(values, helper: FormikHelpers<LoginForm>) => {
				hdfService
					.loginUser(values.mobile, values.pin)
					.then(user => {
						if (user) {
							appInsights.trackEvent({ name: AppInsEvents.UserLogin });

							setCurrentUser(user);
							helper.resetForm();
							helper.setSubmitting(false);
							if (rememberUser) HDFUser.SaveUserInCache(user);

							if (urlParams && urlParams.project){
								history.push('/form' + location.search);
							}else{
								history.push('/main' + location.search);
							}

						} else {
							appInsights.trackEvent({ name: AppInsEvents.UserLoginError });

							lerror('Some error in login');
							helper.setSubmitting(false);
							setError(true);
						}
					})
					.catch(e => {
						lerror(e);
						appInsights.trackException({ exception: new Error(e) });
					});
			}}
			validationSchema={schema}
			validateOnChange={true}
			validateOnBlur={true}
			validateOnMount={true}
			render={(formProps: FormikProps<LoginForm>) => {
				log('Validation Errors: %o', formProps.errors);
				return (
					<Form onSubmit={formProps.handleSubmit}>
						<div className="ms-Grid" dir="ltr">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-lg12">
									<Stack tokens={{ childrenGap: 4 }} key="HDF-User-login">
										<Field name="mobile">
											{field => (
												<FormikTextField
													{...field}
													styles={textBoxPinCode}
													disabled={formProps.isSubmitting}
													label="Please enter a valid mobile number"
													//placeholder="Please enter a valid mobile number"
													required={true}
													onGetErrorMessage={_getErrorMessageForMobile}
													type="tel"
												/>
											)}
										</Field>
										<Field name="pin">
											{field => (
												<FormikTextField
													styles={{
														...textBoxPinCode,
														root: {
															paddingTop: '0px',
															paddingBottom: '25px'
														},
														revealButton: {
															height: 40,
															width: 38
														},
														revealIcon: {
															fontSize: 22
														}
													}}
													style={{}}
													{...field}
													disabled={formProps.isSubmitting}
													required={true}
													number
													maxLength={6}
													minLength={6}
													autocomplete="off"
													label="Enter your PIN"
													type="password"
												/>
											)}
										</Field>
										<Checkbox
											label="Remember me"
											defaultChecked={lscache.get(rememberCacheKey)}
											onChange={(ev, isChecked) => {
												lscache.set(rememberCacheKey, isChecked ?? false, process.env.REACT_APP_CACHEEXPMINS);

												setRememberUser(isChecked ?? false);
												
											}}
										/>
									</Stack>
									{error && (
										<Label
											styles={{
												root: {
													color: 'red',
													textAlign: 'center'
												}
											}}>
											The mobile number and PIN are not recognised. Either register your mobile, or check it and try again. You can reset your PIN using the same link.
										</Label>
									)}
									<Stack tokens={{ childrenGap: 8 }}>
										<Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
											<PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
												Log In
											</PrimaryButton>

											<DefaultButton
												type="button"
												styles={buttonStyle}
												onClick={() => {
													formProps.resetForm();
													history.push('/' + location.search);
												}}
												disabled={formProps.isSubmitting}>
												Cancel
											</DefaultButton>
										</Stack>
										<Stack styles={{ root: { width: '100%' } }}>
											{' '}
											<Separator />
										</Stack>

										<Link
											style={{ textAlign: 'center' }}
											onClick={() => {
												history.push('/new' + location.search);
											}}
											disabled={formProps.isSubmitting}>
											Click here to sign up or to change your PIN
										</Link>
									</Stack>
									{formProps.isSubmitting && <ProgressIndicator />}
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		/>
	);
};

export default Login;