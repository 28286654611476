
import { useLocation } from 'react-router-dom';

export const useQuery = <T>(): T => {
	const query = new URLSearchParams(useLocation().search);
	let qParams:any = {};

	query.forEach((v, k) => {
		qParams[k] = v;
	});

	const obj = {} as T;
	Object.keys(obj).forEach(key => {
		console.log(typeof key);
	  })
	

	return qParams as T;
};


