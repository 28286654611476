import React, { createContext, useState } from 'react';
import HDFUser from '../model/HDFUser';

export const HDFUserContext = createContext<{
	currentUser: HDFUser|undefined;
	setCurrentUser: (newVal: any) => void;
}>({
	currentUser: undefined,
	setCurrentUser: (newVal: any) => {}
});

const HDFUserContextWrapper = props => {
	const [currentUser, setCurrentUser] = useState();
	return <HDFUserContext.Provider value={{ currentUser, setCurrentUser }}>{props.children}</HDFUserContext.Provider>;
};

export default HDFUserContextWrapper;