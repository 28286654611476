import { useBoolean, useId } from '@uifabric/react-hooks';
import {
	Dialog,
	DialogFooter,
	DialogType,
	FontIcon,
	FontWeights,
	getTheme,
	IconButton,
	IIconProps,
	Label,
	Link,
	mergeStyleSets,
	Modal,
	PrimaryButton,
	ProgressIndicator,
	Separator,
	Stack
} from 'office-ui-fabric-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HDFUser, { EvidenceFileType, EvidenceType, HDFUserEvidence, HDFUserEvidenceRequest, pdfMimeType } from '../model/HDFUser';
import COVIDFormsService from '../service/HDFApp.service';
import { HDFUserContext } from '../utils/HDFUserContextWrapper';
import { buttonStyle } from '../style/Styles';
import CovidForm from '../model/CovidForm';
import moment from 'moment';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AppInsEvents } from '../utils/Constants';
import { b64toBlob, inputFileType, loadFile } from '../utils/filePickerUtils';
import { useFilePicker } from '../utils/useFilePicker';
import { SharedColors } from '@fluentui/theme';
import AppDeleteDialog from './subComponents/AppDeleteDialog';

const theme = getTheme();
const debug = require('debug');
const log = debug('hdf:usermain');
const lerror = debug('hdf:errors');

const evidences = [
	{
		type: 'vaccination',
		label: 'Vaccination Status'
	},
	{
		type: 'testresult',
		label: 'Test Result'
	},
	{
		type: 'exemption',
		label: 'Medical Exemption'
	},
	{
		type: 'residence',
		label: 'Proof of Address'
	},
	{
		type: 'permit',
		label: 'Permit'
	}
];

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
	container: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'stretch',
		maxWidth: 'calc(100% - 16px)'		
	},
	header: [
		theme.fonts.medium,
		{
			flex: '1 1 auto',
			borderTop: `4px solid ${theme.palette.themePrimary}`,
			color: theme.palette.neutralPrimary,
			display: 'flex',
			alignItems: 'center',
			fontWeight: FontWeights.semibold,
			padding: '5px 5px 5px 24px'
		}
	],
	body: {
		flex: '4 4 auto',
		padding: '0 0px 0px 0px',
		overflowY: 'hidden',
		selectors: {
			p: { margin: '14px 0' },
			'p:first-child': { marginTop: 0 },
			'p:last-child': { marginBottom: 0 }
		}
	}
});
const iconButtonStyles = {
	root: {
		color: theme.palette.neutralPrimary,
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px'
	},
	rootHovered: {
		color: theme.palette.neutralDark
	}
};

const UserMain = (props: any) => {
	let hdfService: COVIDFormsService = useMemo(() => new COVIDFormsService(), []);
	let history = useHistory();
	let location = useLocation();
	const appInsights = useAppInsightsContext();

	const { currentUser, setCurrentUser } = React.useContext(HDFUserContext);
	const [isImageModalOpen, { setTrue: showImageModal, setFalse: hideImageModal }] = useBoolean(false);
	const [isLoading, { setTrue: showIsLoading, setFalse: hideIsLoading }] = useBoolean(false);
	const [isUploadingVacc, { setTrue: showUploadingVacc, setFalse: hideUploadingVacc }] = useBoolean(false);
	const [isDeletingEvidence, { setTrue: showDeletingEvidence, setFalse: hideDeletingEvidence }] = useBoolean(false);

	const titleId = useId('imageViewer');
	const [currentImage, setCurrentImage] = useState<string>('');
	const [currentImageType, setCurrentImageType] = useState<string>('');
	const [currentEvidenceId, setCurrentEvidenceId] = useState<string>('');

	const [lastCheckin, setLastCheckin] = useState<CovidForm | undefined>();
	const { files, openFileSelector, errors, HiddenFileInput } = useFilePicker({
		imageQuality: 0.92,
		resizeImage: true
	});

	useEffect(() => {
		const usr = HDFUser.GetUserFromCache();
		if (currentUser === undefined) {
			if (usr.id === undefined) {
				log('User not logged in, will redirect to login page.');
				history.push('/' + location.search);
			} else {
				setCurrentUser(usr);
				showIsLoading();
				hdfService
					.getUserForms(usr.mobile!)
					.then((allLogins: CovidForm[]) => {
						setLastCheckin(allLogins[0]);
						hideIsLoading();
					})
					.catch(e => {
						lerror(e);
						hideIsLoading();
						appInsights.trackException({ exception: new Error(e) });
					});
			}
		} else {
			showIsLoading();
			hdfService
				.getUserForms(currentUser.mobile!)
				.then((allLogins: CovidForm[]) => {
					setLastCheckin(allLogins[0]);
					hideIsLoading();
				})
				.catch(e => {
					lerror(e);
					hideIsLoading();
					appInsights.trackException({ exception: new Error(e) });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	useEffect(() => {
		if (files) {
			showIsLoading();
			const file = files.files[0];
			const fileType = file.type;
			log('File selected');
			loadFile(file)
				.then(file => {
					hideIsLoading();
					if (isUploadingVacc) hideUploadingVacc();
					upserEvidence(file, files.fileType, fileType);
				})
				.catch(error => {
					lerror(error);
					appInsights.trackException({ exception: new Error(error) });
				});
		}

		if (errors.hasInvalidImage || errors.hasInvalidFileSize) {
			lerror('Error with the images');
			if (isUploadingVacc) hideUploadingVacc();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	const upserEvidence = (photoData: string, imagetype: string, ftype: string) => {
		if (imagetype && currentUser && currentUser.mobile) {
			showIsLoading();
			if (currentImage) setCurrentImage('');
			//hideCamera();

			const phDataB64: string = photoData.substr(photoData.indexOf(',') + 1);
			const evidenceReq: HDFUserEvidenceRequest = {
				mobile: currentUser.mobile,
				file: phDataB64,
				type: imagetype as EvidenceType,
				filetype: ftype.toLowerCase() === pdfMimeType ? ('pdf' as EvidenceFileType) : ('image' as EvidenceFileType)
			};
			hdfService
				.upsertEvidence(evidenceReq)
				.then((user: HDFUser) => {
					appInsights.trackEvent({ name: AppInsEvents.EvidenceUpload });

					log('Evidence uploaded');
					setCurrentUser(user);
					hideIsLoading();
					if (currentImageType) setCurrentImageType('');
				})
				.catch(e => {
					appInsights.trackEvent({ name: AppInsEvents.EvidenceUploadError });
					lerror(e);
					showIsLoading();
					if (currentImageType) setCurrentImageType('');
					appInsights.trackException({ exception: new Error(e) });
				});
		}
	};

	const viewEvidence = (evType: string) => {
		const evidence: HDFUserEvidence = currentUser!.evidences.find(e => e.type.toString().endsWith(evType))!;
		showIsLoading();
		hdfService
			.getEvidence(evidence.id)
			.then(imageString => {
				log('Evidence retrieved');
				if (evidence.filetype === 'pdf') {
					log('PDF file selected');
					const file = b64toBlob(imageString, 'application/pdf');
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);
					let a = document.createElement('a');
					a.href = fileURL;
					a.setAttribute('target', '_blank');
					a.click();
				} else {
					setCurrentImageType(evType);
					setCurrentImage(imageString);
					showImageModal();
				}

				hideIsLoading();
			})
			.catch(e => {
				lerror(e);
				hideIsLoading();
				appInsights.trackException({ exception: new Error(e) });
			});
	};

	const modalImageViewer = () => {
		return (
			<>
				{currentImage && (
					<Modal
						titleAriaId={titleId}
						isOpen={isImageModalOpen}
						onDismiss={() => {
							setCurrentImage('');
							hideImageModal();
						}}
						isBlocking={false}
						containerClassName={contentStyles.container}>
						<div className={contentStyles.header}>
							<span id={titleId}>{evidences.find(e => e.type === currentImageType)!.label}</span>
							<IconButton
								styles={iconButtonStyles}
								iconProps={cancelIcon}
								ariaLabel="Close popup modal"
								onClick={() => {
									setCurrentImage('');
									hideImageModal();
								}}
							/>
						</div>
						<div className={contentStyles.body}>
							<img
								src={`data:image/png;base64,${currentImage}`}
								alt="Evidence"
								style={{
									width: '100%',
									objectFit: 'contain',
									display: 'block'
								}}></img>
						</div>
					</Modal>
				)}
			</>
		);
	};

	return (
		<>
			{currentUser && (
				<>
					<Stack horizontal={false} horizontalAlign="center" tokens={{ childrenGap: 12 }}>
						<Label styles={{ root: { ...theme.fonts.xLarge } }}>
							{currentUser?.firstname} {currentUser?.lastname}
						</Label>
						{lastCheckin && (
							<Stack horizontal={false} horizontalAlign="center">
								<Label>Last Check In:</Label>
								<Label styles={{ root: { ...theme.fonts.large } }}>{lastCheckin.project}</Label>
								<Label styles={{ root: { ...theme.fonts.smallPlus } }}>{lastCheckin.location}</Label>
								<Stack horizontalAlign="center">
									{lastCheckin.result === 'granted' ? (
										<img style={{ maxWidth: 70 }} alt="granted" className="confirmationImg" src="/imgs/granted.png" />
									) : (
										<img style={{ maxWidth: 70 }} alt="denied" className="confirmationImg" src="/imgs/denied.png" />
									)}
									<div className="confirmationDateBox">
										<div className="confirmationDateDayMonth">{moment(lastCheckin.date).format('D')}</div>
										<div className="confirmationDateMonth">{moment(lastCheckin.date).format('MMMM')}</div>
										<div className="confirmationDateDayWeek">{moment(lastCheckin.date).format('dddd')}</div>
										<div className="confirmationDateTime">{moment(lastCheckin.date).format('LT')}</div>
									</div>
								</Stack>
							</Stack>
						)}
						<Stack horizontal horizontalAlign="center" verticalAlign="center" styles={{ root: { height: 70 } }}>
							<PrimaryButton
								disabled={isLoading}
								styles={{
									...buttonStyle,
									root: {
										height: '40px'
									}
								}}
								onClick={() => {
									history.push('/form' + location.search);
								}}>
								Check In Now
							</PrimaryButton>
						</Stack>
						<Label styles={{ root: { ...theme.fonts.medium } }}>You have provided the following evidence:</Label>
						<Stack horizontal={false} tokens={{ childrenGap: 10 }} grow>
							{isLoading && <ProgressIndicator />}
							{!isUploadingVacc && <HiddenFileInput accept={inputFileType} multiple={false} />}
							{evidences.map(ev => {
								const evidence = currentUser!.evidences.find(e => e.type.toString().endsWith(ev.type));
								const available: boolean = currentUser!.evidences.findIndex(e => e.type.toString().endsWith(ev.type)) > -1;
								const doubleVacc: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'double_vaccination') > -1 && ev.type !== 'vaccination' &&  ev.type !== 'booster_vaccination';

								const isDoubleType: boolean =
									currentUser!.evidences.findIndex(e => e.type.toString() === 'double_vaccination') > -1 && ev.type.toLowerCase().endsWith('vaccination');
									
									const isSingleType: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'vaccination') > -1 && ev.type.toLowerCase().endsWith('vaccination');
																		
									const isBoosterType: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'booster_vaccination') > -1 && ev.type.toLowerCase().endsWith('vaccination');
									
									const isDisableBecuaseDouble: boolean = doubleVacc && ev.type !== 'testresult' && ev.type !== 'residence' && ev.type !== 'permit';
								return (
									<Stack key={ev.type} horizontal horizontalAlign="start" tokens={{ childrenGap: 3 }} verticalFill verticalAlign="center" grow>
										<FontIcon
											aria-label={available || isDisableBecuaseDouble ? 'complete' : 'error'}
											iconName={available || isDisableBecuaseDouble ? 'CompletedSolid' : 'StatusErrorFull'}
											style={{
												color: available || isDisableBecuaseDouble ? 'green' : 'red',
												lineHeight: '32px'
											}}
										/>

										<Label
											styles={{
												root: {
													...theme.fonts.medium,
													minWidth: 110,
													maxWidth: 110
												}
											}}>
											{isSingleType ? `${ev.label} - Single` : isDoubleType ? `${ev.label} - Double` : isBoosterType ? `${ev.label} - Booster` : ev.label}
										</Label>
										<PrimaryButton
											styles={{
												root: {
													backgroundColor: SharedColors.red10,
													borderColor: SharedColors.red10,
													minWidth: 40,
													padding: '0px 10px'
												},
												rootHovered: {
													backgroundColor: SharedColors.red20,
													borderColor: SharedColors.red20
												},
												rootPressed: {
													backgroundColor: SharedColors.red20,
													borderColor: SharedColors.red20
												}
											}}
											onClick={() => {
												if (evidence) {
													setCurrentEvidenceId(evidence.id);
													setCurrentImageType(ev.type);
													showDeletingEvidence();
												}
											}}
											disabled={!available || isLoading || isDisableBecuaseDouble}>
											X
										</PrimaryButton>
										<PrimaryButton onClick={() => viewEvidence(ev.type)} disabled={!available || isLoading || isDisableBecuaseDouble}>
											View
										</PrimaryButton>
										<PrimaryButton
											onClick={() => {
												if (ev.type.endsWith('vaccination')) {
													showUploadingVacc();
												} else {
													openFileSelector(ev.type);
												}
											}}
											disabled={isLoading || isDisableBecuaseDouble}>
											{available ? 'Update' : 'Upload'}
										</PrimaryButton>
									</Stack>
								);
							})}
							<Link
								style={{ textAlign: 'center' }}
								onClick={() => {
									history.push('/new' + location.search);
								}}
								disabled={isLoading}>
								Click here to change your information and your PIN
							</Link>
						</Stack>
						<Stack styles={{ root: { width: '100%' } }}>
							<Separator />
						</Stack>
						<Link
							style={{ textAlign: 'center' }}
							onClick={() => {
								history.push('/history' + location.search);
							}}
							disabled={isLoading}>
							Check In History
						</Link>
					</Stack>
					<AppDeleteDialog
						hidden={!isDeletingEvidence}
						text={`Do you want to delete the ${evidences.find(e => e.type === currentImageType)?.label} evidence`}
						toogleAction={hideDeletingEvidence}
						onConfirm={() => {
							if (currentEvidenceId) {
								showIsLoading();
								hdfService.deleteEvidence(currentUser, currentEvidenceId).then(user => {
									setCurrentUser(user);
									setCurrentImageType('');
									hideIsLoading();
								});
							}
						}}
					/>
					{modalImageViewer()}
					{isUploadingVacc && (						 
						<Dialog 
							hidden={!isUploadingVacc}
							onDismiss={hideUploadingVacc}
							dialogContentProps={{
								type: DialogType.normal,								
								title: 'Vaccination Evidence',
								subText: 'Have you had a single or double or booster doses of Covid vaccine?'
							}}
							modalProps={{
								isBlocking: true,								
								styles: { main: { width: 300 } },
								dragOptions: undefined
							}}>
							<HiddenFileInput accept={inputFileType} multiple={false} />
							
							<DialogFooter styles={{ actions: { display: 'flex',alignItems: 'center' },actionsRight: {display: 'grid',textAlign: 'left',marginLeft:'40px'} }}>
								<PrimaryButton style={{width:'177px'}}  onClick={() => openFileSelector('vaccination')} text="One Dose" />
								<br/>								
								<PrimaryButton style={{width:'177px'}}  onClick={() => openFileSelector('double_vaccination')} text="Two Doses" />								
								<br/>
								<PrimaryButton style={{width:'177px'}}  onClick={() => openFileSelector('booster_vaccination')} text="Booster" />
							
							</DialogFooter>							
						</Dialog>
					)}
				</>
			)}
		</>
	);
};

export default UserMain;
