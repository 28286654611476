import { v4 as uuidv4 } from 'uuid';

interface Symptoms{
    fever:boolean;
    cough:boolean;
    shortnessOfBreath:boolean;
    soreThroat:boolean;
}

export interface FormUrlParams {
	rat?: string;
	project?: string;
	site?: string;
}


export default class CovidForm{
    id:string;
    deviceId:string | undefined;
    firstname?:string;
    lastname?:string;
    project?:string;
    location?:string;    
    date:Date;
    staffType?:string;
    employer?:string;
    mobile?:string;
    email?:string;
    projectMobiles?:string[];
    siteMobiles?:string[];
    projectEmail?:string[];
    siteEmail?:string[];
    submissionTime:number = -1;
    hasSymptoms:boolean = false;
    symptoms?:Symptoms;
    travel:boolean = false;
    inContactTested:boolean = false;
    diagnosed:boolean = false;
    quarantineOrder:boolean = false;
    inContactWithDiagnosed:boolean = false;
    contactedByGov?:boolean = false;
    declaration:boolean = false;
    privacy:boolean = false;
    reCaptchaRating?:string;
    result:string;
    company:string;
    visitedHotspot:boolean = false;
    projectVisited?: string;
    vaccinationStatus?:string;
    rapidAntigenTesting?:string;
    affectedWorker?:string;
    residentInNSWHODA:boolean = false;
    vaccFirstDoseDate?:Date;
    vaccinationEvidence:boolean = false;
    testresultEvidence:boolean = false;
    exemptionEvidence:boolean = false;
    permitByServiceNSW:boolean = false;
    isTraceCheckIn:boolean = false;
    constructor(){
        this.id = uuidv4();
        this.date = new Date();
        this.declaration = false;
        this.residentInNSWHODA = false;
        this.result = "denied";
        this.company = "cpb";
        this.symptoms = {
            fever: false,
            cough: false,
            shortnessOfBreath: false,
            soreThroat:false,
        }
    }
}