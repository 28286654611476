import { IButtonStyles, ICheckboxStyleProps, ICheckboxStyles, ILabelStyleProps, ILabelStyles, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles } from "office-ui-fabric-react";

export const buttonStyle: IButtonStyles = {
	root: {
		height: '50px',
		minWidth: '95px'
	}
};

export const confirmationPersonInfoStyle: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> = {
	root: {
		padding: '1px'
	}
};

export const checkBoxAgreement: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> = {
	root: {
		padding: '25px 0px'
	}
};


export const textBoxPinCode: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
	root: {
		padding: '25px 0px'
	},
    fieldGroup:{
        height:45
    },
    field:{
        fontSize:26,
        textAlign:"center",
        letterSpacing:"10px"
    },
    wrapper:{
        textAlign:"center"
    }

};