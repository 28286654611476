import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { isDev } from './utils/Constants';
const lscache = require('lscache');

lscache.setExpiryMilliseconds(60000);

const version = process.env.REACT_APP_VERSION;

if (!lscache.get('APPVERSION') || lscache.get('APPVERSION') !== version){
	lscache.flush();
	lscache.set('APPVERSION',version, process.env.REACT_APP_CACHEEXPMINS);
}

const debug = require('debug');

debug.enable('hdf:errors');
if (isDev()) {
	debug.enable('hdf:*');
}

const gacode = process.env.REACT_APP_GA;
ReactGA.initialize(gacode || '');
ReactGA.pageview(window.location.pathname + window.location.search);

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights: ApplicationInsights = new ApplicationInsights({
	config: {
		instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory }
		}
	}
});
appInsights.loadAppInsights();
appInsights.trackPageView();

ReactDOM.render(
	<BrowserRouter>
		<AppInsightsContext.Provider value={reactPlugin}>
			<App />
		</AppInsightsContext.Provider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
