import { useBoolean } from '@uifabric/react-hooks';
import moment from 'moment';
import { FontIcon, getTheme, Label, PrimaryButton, Separator, Spinner, Stack } from 'office-ui-fabric-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CovidForm from '../model/CovidForm';
import HDFUser from '../model/HDFUser';
import COVIDFormsService from '../service/HDFApp.service';
import { buttonStyle } from '../style/Styles';
import { HDFUserContext } from '../utils/HDFUserContextWrapper';
const theme = getTheme();
const debug = require('debug');
const log = debug('hdf:history');
const lerror = debug('hdf:errors');

const CheckInHistory = (props: any) => {
	let hdfService: COVIDFormsService = useMemo(() => new COVIDFormsService(), []);
	let history = useHistory();
	let location = useLocation();
	const { currentUser, setCurrentUser } = React.useContext(HDFUserContext);
	const [isLoading, { setTrue: showIsLoading, setFalse: hideIsLoading }] = useBoolean(false);
	const [CheckInHistory, setHistory] = useState<CovidForm[]>([]);

	useEffect(() => {
		const usr = HDFUser.GetUserFromCache();

		if (currentUser === undefined) {
			if (usr.id === undefined){
				log('User not logged in, will redirect to login page.');
				history.push('/' + location.search);
			}else{
				setCurrentUser(usr);
			}
		} else {
			showIsLoading();
			hdfService
				.getUserForms(currentUser.mobile!)
				.then((allLogins: CovidForm[]) => {
					log('All history loaded');
					setHistory(allLogins);
					hideIsLoading();
				})
				.catch(e => {
					lerror(e);
					hideIsLoading();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	return (
		<>
			{isLoading ? (
				<Spinner label="Loading Check Ins..." />
			) : (
				<Stack horizontal={false} horizontalAlign="center" verticalAlign="start">
					<Label styles={{ root: { ...theme.fonts.xLarge } }}>
						{currentUser?.firstname} {currentUser?.lastname}
					</Label>
					<Label>My Check In History</Label>
					{CheckInHistory.length === 0 && <Label styles={{ root: { ...theme.fonts.smallPlus } }}>No previous check in found.</Label>}
					{CheckInHistory.map(c => {
						return (
							<Stack key={c.id} horizontal={false} horizontalAlign="center" verticalAlign="start" grow styles={{ root: { width: '100%' } }}>
								<Stack horizontal horizontalAlign="start" verticalAlign="center" grow styles={{ root: { width: '100%' } }}>
									<Stack horizontal={false} styles={{ root: { width: '100%' } }}>
										<Label styles={{ root: { ...theme.fonts.mediumPlus } }}>{c.project}</Label>
										{c.location && c.location !== "none" && <Label styles={{ root: { ...theme.fonts.small } }}>{c.location}</Label>}
										<Label styles={{ root: { ...theme.fonts.small } }}>{moment(c.date).format('DD/MM/yyyy hh:mm:ss a')}</Label>
									</Stack>
									<Stack>
										<FontIcon
											aria-label={c.result === 'granted' ? 'complete' : 'error'}
											iconName={c.result === 'granted' ? 'CompletedSolid' : 'StatusErrorFull'}
											style={{
												color: c.result === 'granted' ? 'green' : 'red',
												lineHeight: '50px',
												fontSize: 24
											}}
										/>
									</Stack>
								</Stack>
								<Stack styles={{ root: { width: '100%' } }}>
									{' '}
									<Separator />
								</Stack>
							</Stack>
						);
					})}
					<Stack
						horizontal
						horizontalAlign="center"
						verticalFill
						verticalAlign="center"
						grow
						tokens={{ childrenGap: 5 }}
						styles={{
							root: {
								backgroundColor: '#fff',
								width: '100%',
								position: 'fixed',
								left: 0,
								bottom: 0,
								height: 65,
								boxShadow: '4px 3px 8px 1px #969696',
								'-webkit-box-shadow': '4px 3px 8px 1px #969696'
							}
						}}>
						<PrimaryButton
							styles={buttonStyle}
							onClick={() => {
								history.push('/main' + location.search);
							}}>
							Close
						</PrimaryButton>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default CheckInHistory;
