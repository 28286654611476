import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Stack, Label, getTheme } from "office-ui-fabric-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { HDFUserContext } from "../utils/HDFUserContextWrapper";
const debug = require('debug');
const log = debug('hdf:error');
const theme = getTheme();

interface IErrorBoundaryProps{
  appInsightsContext:ReactPlugin
}

class InternalErrorBoundary extends React.Component<IErrorBoundaryProps & RouteComponentProps, any> {
    static contextType = HDFUserContext;

    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      this.props.appInsightsContext.trackException({ error: error,exception:errorInfo, severityLevel: SeverityLevel.Error },
        {
          location: window.location,
          sessionInfo: this.context.currentUser
        });
      log(error, errorInfo);
    }
    componentDidUpdate(prevProps) {
      if (prevProps.location.pathname !== this.props.location.pathname && this.state.hasError) {
        this.setState({ hasError: false });
      }
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <Stack horizontal={false} verticalAlign="center" horizontalAlign="center" styles={{root:{height:300}}}>
            <Label styles={{root:{
              ...theme.fonts.large
            }}}>Opsss, sorry something went wrong...</Label>
            <Label styles={{root:{
              ...theme.fonts.medium
            }}}>We will fix it ASAP</Label>
          </Stack>;
      }
  
      return this.props.children; 
    }
  }

const ErrorBoundaryComp = withRouter(InternalErrorBoundary);

export default ErrorBoundaryComp;