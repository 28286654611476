import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import HDFUser from '../model/HDFUser';
import * as yup from 'yup';
import { AppInsEvents, ausMobilePattern, emailValidationError, emailValidationRegex, isDev, mobileValidationError, _getErrorMessageForEmail, _getErrorMessageForMobile } from '../utils/Constants';
import { DefaultButton, IDropdownOption, Label, PrimaryButton, Stack } from 'office-ui-fabric-react';
import { FormikDropdown, FormikTextField } from 'formik-office-ui-fabric-react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { buttonStyle, textBoxPinCode } from '../style/Styles';
import COVIDFormsService from '../service/HDFApp.service';
import { COVIDFormLookup } from '../model/DBModel';
import LoadingShimmer from './subComponents/Loading';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const debug = require('debug');
const log = debug('hdf:userreg');
const lerror = debug('hdf:errors');

const schema = yup.object().shape({
	firstname: yup.string().required('First Name is required'),
	lastname: yup.string().required('Last Name is required'),
	staffType: yup.string().required('Staff type is required'),
	reCaptchaRating: yup.string().required('Captcha not passedRequired'),
	mobile: yup.string().matches(ausMobilePattern, mobileValidationError).required('Required'),
	email: yup.string().matches(emailValidationRegex, emailValidationError),
	employer: yup.string().when('staffType', { is: 'Other', then: s => s.required('Employer is required when Staff type is Other') })
});

const pinConfirmation = yup.object().shape({
    pin: yup.string().required('Please enter a four digits pin').length(6,"Your PIN must be 6 digits"),
    pinConf: yup.string().test('pin-match', 'PIN do not match', function(value){
      const { pin } = this.parent;
      return pin === value;
    })
})

interface PinConfirmationForm{
    pin:string;
    pinConf:string;
}

const UserRegistration = (props: any) => {
	let hdfService: COVIDFormsService = useMemo(() => new COVIDFormsService(), []);
	let history = useHistory();
	let location = useLocation();
	const appInsights = useAppInsightsContext();

	const initUserForm: HDFUser = useMemo(() => {
		return HDFUser.GetUserFromCache();
	}, []);

	const [formConfig, setFormConfig] = useState<COVIDFormLookup | undefined>();

	const [staffType, setStaffType] = useState<IDropdownOption[]>([]);

	const [waitingValidation, setWaitingValidation] = useState(false);
    const [pinSetupPhase, setPinSetupPhase] = useState(false);

    const [verificationCode, setVerificationCode] = useState(0);
	const [currentUser, setCurrentUser] = useState<HDFUser|undefined>();

    const confCodeSchema = useMemo(() => yup.object().shape({
        code: yup.string().required('Please enter the confirmation code').length(6,"The code must be six digits").oneOf([verificationCode.toString()], "Verification code does not match")
    })
    , [verificationCode]) 

    useEffect(() => {
		hdfService
			.getLookupForForm(COVIDFormsService.formNameGlb, window.location.hostname || window.location.host)
			.then((lookupdata: COVIDFormLookup) => {
				let _staffType: IDropdownOption[] = [];
				lookupdata.staffType.forEach(val => {
					_staffType.push({ key: val, text: val });
				});
				setStaffType(_staffType);
				setFormConfig(lookupdata);
			})
			.catch(error => {
				lerror(error);
				appInsights.trackException({ exception: new Error(error) });

			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submiteForm = (values: HDFUser, helper: FormikHelpers<HDFUser>): void => {
		log('Will try to save user %s', values);

		hdfService.upsertUser(values).then((newUser:HDFUser) => {
            if (newUser.mobile){

                if (isDev()){
                    log("Working in DEV, will set a fake con code 111111");
                    helper.setSubmitting(false);
                    setVerificationCode(111111);
                    setCurrentUser(newUser);
                    setWaitingValidation(true);
                }else{
                    hdfService.getConfirmationCode(newUser.mobile).then((code) => {
                        helper.setSubmitting(false);
                        setVerificationCode(code);
                        setCurrentUser(newUser);
                        setWaitingValidation(true);
                    });
                }   
            }
        });
	};

	const CodeConfirmation = () => {
		return (
			<Formik
				initialValues={{code:""}}
				onSubmit={(values, helper: FormikHelpers<{code: string }>) => {
                    if (currentUser && values.code && Number.parseInt(values.code) === verificationCode){
                        
                        hdfService.upsertUser({
                            ...currentUser,
                            valid:true
                        }).then((newUser:HDFUser) => {
                            helper.setSubmitting(false);
                            setCurrentUser(newUser);
                            setPinSetupPhase(true);
                        });
                    }
                }}
				validationSchema={confCodeSchema}
				validateOnChange={true}
				validateOnBlur={true}
				validateOnMount={true}
				render={(formProps: FormikProps<{ code: string }>) => {
					log('Validation Errors: %o', formProps.errors);

					return (
						<Form onSubmit={formProps.handleSubmit}>
							<div className="ms-Grid" dir="ltr">
								<div className="ms-Grid-row">
									<div className="ms-Grid-col ms-lg12">
										<Stack tokens={{ childrenGap: 12 }} key="HDF-User-Registration">
											<Field name="code">
												{field => (
													<FormikTextField
                                                        styles={textBoxPinCode}
														{...field}
														disabled={formProps.isSubmitting}
														label="Enter the Verification code just sent to you"
														required={true}
														number
														maxLength={6}
														minLength={6}
                                                        autocomplete="off"
													/>
												)}
											</Field>

											<Label>If you don't receive an SMS within 3 minutes, press the back button and check the mobile phone number you entered</Label>
										</Stack>
										<Stack tokens={{ childrenGap: 8 }}>
											<Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
												<PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
													Confirm
												</PrimaryButton>

												<DefaultButton
													type="button"
													styles={buttonStyle}
													onClick={() => {
														formProps.resetForm();
														setWaitingValidation(false);
													}}
													disabled={formProps.isSubmitting}>
													Back
												</DefaultButton>
											</Stack>
										</Stack>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			/>
		);
	};

    const PinSetup = () => {
		return (
			<Formik
				initialValues={{pin:"", pinConf:""}}
				onSubmit={(values, helper: FormikHelpers<PinConfirmationForm>) => {
                    if (currentUser && currentUser.mobile){
                        hdfService.setUserPIN(currentUser.mobile, values.pin).then(() => {
							appInsights.trackEvent({ name: AppInsEvents.UserSignUp });

                            setWaitingValidation(false);
                            setCurrentUser(undefined);
                            setPinSetupPhase(false);
                            setVerificationCode(0);
                            helper.setSubmitting(false);
                            helper.resetForm();
                            history.push('/' + location.search);

                        });
                    }
                }}
				validationSchema={pinConfirmation}
				validateOnChange={true}
				validateOnBlur={true}
				validateOnMount={true}
				render={(formProps: FormikProps<PinConfirmationForm>) => {
					log('Validation Errors: %o', formProps.errors);

					return (
						<Form onSubmit={formProps.handleSubmit}>
							<div className="ms-Grid" dir="ltr">
								<div className="ms-Grid-row">
									<div className="ms-Grid-col ms-lg12">
										<Stack tokens={{ childrenGap: 12 }} key="HDF-User-Registration">
                                        <Stack horizontalAlign="center">

										<img style={{maxWidth:70}} alt="granted" className="confirmationImg" src="/imgs/granted.png" />
                                        <Label>Thanks for verifying your Mobile Number</Label>
                                        <Label>To protect the information you enter, please enter and verify a PIN number. Do not share your PIN with anyone</Label>

							            </Stack>
											<Field name="pin">
												{field => (
													<FormikTextField
													styles={{...textBoxPinCode,
														root:{
															paddingTop:"10px",
															paddingBottom:"5px"
														}}}
														{...field}
														disabled={formProps.isSubmitting}
														number
														maxLength={6}
														minLength={6}
                                                        autocomplete="off"
													/>
												)}
											</Field>
                                            <Field name="pinConf">
												{field => (
													<FormikTextField
													styles={{...textBoxPinCode,
														root:{
															paddingTop:"0px",
															paddingBottom:"15px"
														}}}
														{...field}
														disabled={formProps.isSubmitting}
														label="Re-enter the PIN"
														number
														maxLength={6}
														minLength={6}
                                                        autocomplete="off"
													/>
												)}
											</Field>

										</Stack>
										<Stack tokens={{ childrenGap: 8 }}>
											<Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
												<PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
													Submit
												</PrimaryButton>
											</Stack>
										</Stack>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			/>
		);
	};

	return (
		<>
			{formConfig === undefined ? (
				<LoadingShimmer />
			) : (
				<>
					{waitingValidation ? (
                        pinSetupPhase ?
                        PinSetup()
                        :
						CodeConfirmation()
					) : (
						<Formik
							initialValues={initUserForm}
							onSubmit={submiteForm}
							validationSchema={schema}
							validateOnChange={true}
							validateOnBlur={false}
							validateOnMount={true}
							render={(formProps: FormikProps<HDFUser>) => {
								log('Validation Errors: %o', formProps.errors);

								return (
									<Form onSubmit={formProps.handleSubmit}>
										<div className="ms-Grid" dir="ltr">
											<div className="ms-Grid-row">
												<div className="ms-Grid-col ms-lg12">
													<Stack tokens={{ childrenGap: 12 }} key="HDF-User-Registration">
														<Label>To secure the information you provide, we need to validate your details. Please complete the form below and press submit</Label>
														<Field name="firstname">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="First Name" required={true} />}</Field>
														<Field name="lastname">{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="Last Name" required={true} />}</Field>
														<Field name="staffType">
															{field => <FormikDropdown {...field} disabled={formProps.isSubmitting} label="Staff Type" required={true} options={staffType} />}
														</Field>
														{(formProps.values.staffType === 'Other' || formProps.values.staffType === 'Sub Contractor') && (
															<Field name="employer">
																{field => <FormikTextField {...field} disabled={formProps.isSubmitting} label="Employer Name" required={true} />}
															</Field>
														)}
														<Field name="email">
															{field => (
																<FormikTextField
																	{...field}
																	disabled={formProps.isSubmitting}
																	label="Email"
																	placeholder="Please enter your email address"
																	onGetErrorMessage={_getErrorMessageForEmail}
																/>
															)}
														</Field>
														<Field name="mobile">
															{field => (
																<FormikTextField
																	{...field}
																	disabled={formProps.isSubmitting}
																	label="Mobile Number"
																	placeholder="Please enter a valid mobile number"
																	required={true}
																	onGetErrorMessage={_getErrorMessageForMobile}
																	type="tel"
																/>
															)}
														</Field>
														<Label>We will send a confirmation SMS to this mobile number that contains a verification code for you to enter in the next step</Label>
													</Stack>
													<Stack tokens={{ childrenGap: 8 }}>
														<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_reCAPTCHA_SITEKEY}>
															<GoogleReCaptcha
																onVerify={token => {
																	formProps.setFieldValue('reCaptchaRating', token, true);
																}}
															/>
														</GoogleReCaptchaProvider>

														<Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
															<PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
																Submit
															</PrimaryButton>

															<DefaultButton
																type="button"
																styles={buttonStyle}
																onClick={() => {
																	formProps.resetForm();
																	history.push('/' + location.search);
																}}
																disabled={formProps.isSubmitting}>
																Cancel
															</DefaultButton>
														</Stack>
													</Stack>
												</div>
											</div>
										</div>
									</Form>
								);
							}}
						/>
					)}
				</>
                        )}
		</>
	);
};

export default UserRegistration;
