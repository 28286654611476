import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CovidForm, { FormUrlParams } from '../model/CovidForm';
import { Stack, IDropdownOption, PrimaryButton, DefaultButton, Label, IChoiceGroupOption, DayOfWeek, FontIcon, getTheme, ProgressIndicator } from 'office-ui-fabric-react';
import { FormikTextField, FormikCheckbox, FormikDropdown, FormikToggle, FormikChoiceGroup, FormikDatePicker } from 'formik-office-ui-fabric-react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import Fingerprint2 from 'fingerprintjs2';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { useQuery } from '../utils/HDFHooks';
import LoadingShimmer from './subComponents/Loading';
import { COVIDFormLookupSite, COVIDFormLookup } from '../model/DBModel';
import COVIDFormsService from '../service/HDFApp.service';
import { confirmationPersonInfoStyle, checkBoxAgreement, buttonStyle } from '../style/Styles';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
	AppInsEvents,
	affectedWorkedWorningLabel,
	affectedWorkedLabel,
	cacheKey,
	ausMobilePattern,
	mobileValidationError,
	_getErrorMessageForMobile,
	questionsByState
} from '../utils/Constants';
import { HDFUserContext } from '../utils/HDFUserContextWrapper';
import HDFUser, { EvidenceFileType, EvidenceType, HDFUserEvidenceRequest, pdfMimeType } from '../model/HDFUser';
import { useBoolean } from '@uifabric/react-hooks';
import { useScreenshot } from 'use-react-screenshot';
import { inputFileType, loadFile } from '../utils/filePickerUtils';
import { useFilePicker } from '../utils/useFilePicker';
import { v4 as uuidv4 } from 'uuid';

//https://hdfapp.cpbcon.com.au/?project=${encodeURIComponent(project)}&site=${encodeURIComponent(location)}

const theme = getTheme();

const getQuestionForState = (state: string, question: string): string => {
	let ret: string;

	if (questionsByState[state.toLowerCase()]) {
		ret = questionsByState[state.toLowerCase()][question];
	} else {
		ret = questionsByState['nsw'][question];
	}

	return ret;
};

const debug = require('debug');
const lscache = require('lscache');

const log = debug('hdf:form');
const lerror = debug('hdf:errors');

interface FormState {
	allSites: COVIDFormLookupSite[];
	defaultSMSNumber?: string[];
	projects: IDropdownOption[];
	locations: IDropdownOption[];
	staffType: IDropdownOption[];
	formTitle: string;
	formLogoUrl: string;
	showScreenMessage: string;
	allowed: boolean;
	warning: boolean;
	confirmed: boolean;
	submittedData?: CovidForm;
	privacyStat?: string;
	contactedByGov?: string;
	hotspotQuestion?: string;
	hotspotQuestionDefault?: string;
	isRAT: boolean;
	isAffectedWorkerNo: boolean;
	rapidTestResult?: string;
	initializated: boolean;
}

interface FormProps {
	//appInsights: ApplicationInsights;
	isRAT: boolean;
}
initializeIcons();
moment.locale('en-AU');

const affectedWorkerOption: IChoiceGroupOption[] = [
	{ key: 'yes', text: 'Yes, I have been tested' },
	{ key: 'no', text: 'No, I have not been tested' },
	{ key: 'na', text: 'Not applicable' }
];

const threeWeeksAgo: Date = moment().subtract(3, 'weeks').toDate();

type HealthFormProps = FormProps;
const HealthForm = (props: HealthFormProps) => {
	let history = useHistory();
	let location = useLocation();
	const appInsights = useAppInsightsContext();
	const { rat: rapidAntigenTestingUrl, project: prjUrl, site: siteUrl } = useQuery<FormUrlParams>();
	const [currentImageType, setCurrentImageType] = useState<string | undefined>();

	const [tracerCheckIn, setTracerCheckIn] = useState(false);
	const [isCheckingIfTracer, { setFalse: setIsNoCheckingIfTracer }] = useBoolean(true);

	const { currentUser, setCurrentUser } = React.useContext(HDFUserContext);
	const [isLoading, { setTrue: showIsLoading, setFalse: hideIsLoading }] = useBoolean(false);
	//const [isFormLoading, { setFalse: hideIsFormLoading }] = useBoolean(true);
	const [isFormLoading, setIsFormLoading] = useState(true);

	const confRef = React.useRef(null);
	const [image, takeScreenshot] = useScreenshot();
	const getImage = () => takeScreenshot(confRef.current);

	const { files, openFileSelector, errors, HiddenFileInput } = useFilePicker({
		imageQuality: 0.92,
		resizeImage: true
	});

	const vaccOptionsNSW: IChoiceGroupOption[] = useMemo(() => {
		const isDoubleVacc: boolean = currentUser !== undefined && currentUser.evidences.findIndex(ev => ev.type === 'double_vaccination') > -1;
		return [
			{ key: 'double', text: 'Two Doses of a COVID-19 Vaccine' },
			{ key: 'single', text: 'One Dose of a COVID-19 Vaccine', disabled: isDoubleVacc },
			{ key: 'none', text: 'I am not vaccinated', disabled: isDoubleVacc },
			{ key: 'exemption', text: 'I have a certified medical contraindication and a have been tested for COVID-19 within the preceding 72 hours', disabled: isDoubleVacc }
		];
	}, [currentUser]);

	const vaccOptionsVIC: IChoiceGroupOption[] = useMemo(() => {
		const isDoubleVacc: boolean = currentUser !== undefined && currentUser.evidences.findIndex(ev => ev.type === 'double_vaccination') > -1;
		return [
			{ key: 'double', text: 'I have had two doses of a Covid-19 Vaccine' },			
			{ key: 'booster', text: 'I have had two doses and booster shot of a Covid-19 vaccine', disabled: isDoubleVacc },
			/*{ key: 'single', text: 'I have had one does of a Covid-19 Vaccine', disabled: isDoubleVacc },
			{ key: 'booked', text: 'I am booked in to be vaccinated', disabled: isDoubleVacc },
			{ key: 'none', text: 'I am not vaccinated', disabled: isDoubleVacc },*/
			{ key: 'exemption', text: 'I have a medical exemption from being vaccinated', disabled: isDoubleVacc }
		];
	}, [currentUser]);

	let hdfService: COVIDFormsService = useMemo(() => new COVIDFormsService(), []);


	const validateFirstDose = (vaccinationStatus, location, project, residentInNSWHODA, schema): boolean => {
		const selectedProject = state.allSites.find(s => s.project === project);
		let projectState = selectedProject?.state ?? 'nsw';
		if (selectedProject && location) {
			const site = selectedProject.locations.find(l => l.name === location);
			if (site?.state) projectState = site?.state;
		}

		if (vaccinationStatus === 'single' && projectState.toLowerCase() === 'nsw' && residentInNSWHODA === true) {
			return true;
		}	
		return false;
	}

	const schema = yup.object().shape({
		rapidAntigenTesting: yup.string(),
		firstname: yup.string().required('First Name is required'),
		lastname: yup.string().required('Last Name is required'),
		project: yup.string().required('Project is required'),
		location: yup.string().when('project', (project, schema) => {
			return getSitesForProject(project).length > 0 ? schema.required('Site is required') : schema;
		}),
		staffType: yup.string().required('Staff type is required'),
		reCaptchaRating: yup.string().required('Captcha not passedRequired'),
		mobile: yup.string().matches(ausMobilePattern, mobileValidationError).required('Required'),
		declaration: yup.mixed().oneOf([true], 'The terms and conditions must be accepted.'),
		privacy: yup.mixed().oneOf([true], 'The Privacy consent must be accepted.'),
		employer: yup.string().when('staffType', { is: ['Other', 'Sub Contractor'], then: s => s.required('Employer is required when Staff type is Other') }),
		vaccinationStatus: yup.string().when('residentInNSWHODA', {
			is: true,
			then: yup.string().required('Vaccination status is required')
		}),
		vaccFirstDoseDate: yup.date().when(['vaccinationStatus', 'location', 'project', 'residentInNSWHODA'], {
			is: validateFirstDose,
			then: yup.date().required('Date Administered is required')
		}) /*,
		vaccinationEvidence: yup.bool().when('vaccinationStatus', {
			is: vaccinationEvidenceValidator,
			then: yup.bool().is([true])
		}),
		testresultEvidence: yup.bool().when(['vaccinationStatus', 'vaccFirstDoseDate'], {
			is: testResultEvidenceValidator,
			then: yup.bool().is([true])
		}),
		exemptionEvidence: yup.bool().when('vaccinationStatus', {
			is: exemptionEvidenceValidator,
			then: yup.bool().is([true])
		})*/
	});

	const [state, setState] = useState<FormState>({
		allSites: [],
		projects: [],
		locations: [],
		staffType: [],
		formTitle: '',
		formLogoUrl: '',
		showScreenMessage: '',
		confirmed: false,
		allowed: false,
		warning: false,
		isRAT: props.isRAT,
		isAffectedWorkerNo: false,
		initializated: false
	});

	const initialForm: CovidForm = useMemo(() => {
		let initialForm: CovidForm = new CovidForm();
		try {
			if (currentUser) {
				initialForm.firstname = currentUser.firstname;
				initialForm.lastname = currentUser.lastname;
				initialForm.staffType = currentUser.staffType;
				initialForm.mobile = currentUser.mobile;
				initialForm.email = currentUser.email;
				initialForm.employer = currentUser.employer;
			}

			const cachedUser: any = lscache.get(cacheKey);
			if (cachedUser) {
				initialForm.location = cachedUser.location.replace(/[^\x20-\x7E]+/g, '');
				initialForm.project = cachedUser.project.replace(/[^\x20-\x7E]+/g, '');

				appInsights.trackEvent({ name: AppInsEvents.CachedUser });

				if (cachedUser.projectVisisted && !state.isRAT) {
					initialForm.projectVisited = cachedUser.projectVisisted.replace(/[^\x20-\x7E]+/g, '');
				}

				initialForm.affectedWorker = 'na';
			} else {
				//initialForm.vaccinationStatus = 'na';
				initialForm.affectedWorker = 'na';
			}

			const isDoubleVacc: boolean = currentUser !== undefined && currentUser.evidences.findIndex(ev => ev.type === 'double_vaccination') > -1;
			const isSingleVacc: boolean = currentUser !== undefined && currentUser.evidences.findIndex(ev => ev.type === 'vaccination') > -1;
			const isBoosterVacc: boolean = currentUser !== undefined && currentUser.evidences.findIndex(ev => ev.type === 'booster_vaccination') > -1;
			
			const isExVacc: boolean = currentUser !== undefined && currentUser.evidences.findIndex(ev => ev.type === 'exemption') > -1;

			if (isDoubleVacc) initialForm.vaccinationStatus = 'double';
			if (isSingleVacc) initialForm.vaccinationStatus = 'single';
			if (isBoosterVacc) initialForm.vaccinationStatus = 'booster';
			if (isExVacc) initialForm.vaccinationStatus = 'exemption';

			if (prjUrl && prjUrl != null) {
				log('setting project form url: %s', prjUrl);
				initialForm.project = prjUrl.replace(/[^\x20-\x7E]+/g, '');
				appInsights.trackEvent({ name: AppInsEvents.ProjectInURL });
			}

			if (siteUrl && siteUrl != null) {
				log('setting site form url: %s', siteUrl);
				initialForm.location = siteUrl.replace(/[^\x20-\x7E]+/g, '');
			} else {
				if (prjUrl && prjUrl != null) {
					initialForm.location = undefined;
				}
			}

			if (rapidAntigenTestingUrl && rapidAntigenTestingUrl != null) {
				if (initialForm.project) {
					const testReslEncr = rapidAntigenTestingUrl.replace(/[^\x20-\x7E]+/g, '');
					const pk = `${initialForm.location ? initialForm.location.replace(/\s+/g, '_').toLowerCase() : ''}_${initialForm.project.replace(/\s+/g, '_').toLowerCase()}`;

					log('setting Rapid test result form url: %s', rapidAntigenTestingUrl);
					initialForm.rapidAntigenTesting = CryptoJS.AES.decrypt(testReslEncr, pk).toString(CryptoJS.enc.Utf8).toUpperCase();
					appInsights.trackEvent({ name: AppInsEvents.RapidTestResultInURL });
				}
			}
		} catch (error) {
			lerror(error);
			appInsights.trackException({ exception: new Error(error as any) });
		}
		return initialForm;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rapidAntigenTestingUrl, prjUrl, siteUrl, currentUser]);

	useEffect(() => {
		const usr = HDFUser.GetUserFromCache();
		try {
			if (currentUser === undefined) {
				if (usr.id === undefined) {
					log('User not logged in, will redirect to login page.');
					history.push('/' + location.search);
				} else {
					setCurrentUser(usr);
				}
			} else {
				appInsights.trackEvent({ name: AppInsEvents.ProjectsLoad });

				if (siteUrl && prjUrl && isCheckingIfTracer && !rapidAntigenTestingUrl) {
					log("Project and Location found in URL, will chec if it's a tracer check in");
					const sitesOptions = getSitesForProject(prjUrl);
					if (sitesOptions.findIndex(s => s.key === siteUrl) < 0 && siteUrl.startsWith('Site ')) {
						hdfService.getUserForms(currentUser.mobile!).then(forms => {
							const todayForms = forms.filter(f => {
								return !f.isTraceCheckIn && f.project === prjUrl && moment(f.date).format('DDMMYYYY') === moment().format('DDMMYYYY');
							});

							if (todayForms.length > 0) {
								log('Found a previous check-in for the day');
								setTracerCheckIn(true);
								submitTracerCheckIn({ ...todayForms[0], id: uuidv4(), company: 'cpb', isTraceCheckIn: true, location: siteUrl, date: new Date() });
							} else {
								log('Could not find a previous check in today, will open the normal form');
								setIsNoCheckingIfTracer();
								//hideIsFormLoading();
								//setIsFormLoading(false);
							}
						});
					} else {
						log('Location found in the options, will disaply the normal form');
						setIsNoCheckingIfTracer();
						//setIsFormLoading(false);
					}
				} else {
					log('No url parameters, will go ahead as normal');
					setIsNoCheckingIfTracer();
					//setIsFormLoading(false);
				}

				if (!isCheckingIfTracer && !state.initializated) {
					hdfService
						.getLookupForForm(COVIDFormsService.formNameGlb, window.location.hostname || window.location.host)
						.then((lookupdata: COVIDFormLookup) => {
							try {
								appInsights.trackEvent({ name: AppInsEvents.ProjectsLoaded });

								let _projects: IDropdownOption[] = [];
								lookupdata.sites.forEach(val => {
									_projects.push({ key: val.project, text: val.project });
								});

								let _staffType: IDropdownOption[] = [];
								lookupdata.staffType.forEach(val => {
									_staffType.push({ key: val, text: val });
								});

								setState({
									...state,
									allSites: lookupdata.sites,
									projects: _projects,
									staffType: _staffType,
									formTitle: lookupdata.formTitle,
									formLogoUrl: lookupdata.formLogoUrl,
									showScreenMessage: lookupdata.showScreenMessage,
									defaultSMSNumber: lookupdata.defaultSMSNumber,
									privacyStat: lookupdata.privacyStat,
									contactedByGov: lookupdata.contactedByGov,
									hotspotQuestion: lookupdata.hotspotQuestion,
									hotspotQuestionDefault: lookupdata.hotspotQuestionDefault,
									initializated: true
								});

								if (isFormLoading) setIsFormLoading(false);
							} catch (error) {
								appInsights.trackException({ exception: new Error(error as any) });
								setIsFormLoading(false);
							}
						})
						.catch(error => {
							lerror(error);
							appInsights.trackException({ exception: new Error(error) });
						});
				}
			}
		} catch (error) {
			lerror(error);
			appInsights.trackException({ exception: new Error(error as any) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, initialForm, isCheckingIfTracer]);

	useEffect(() => {
		if (image && currentUser) {
			showIsLoading();

			const phDataB64: string = image.substr(image.indexOf(',') + 1);
			const evidenceReq: HDFUserEvidenceRequest = {
				mobile: currentUser.mobile!,
				file: phDataB64,
				type: 'testresult' as EvidenceType,
				filetype: "image"
			};

			hdfService
				.upsertEvidence(evidenceReq)
				.then((user: HDFUser) => {
					appInsights.trackEvent({ name: AppInsEvents.EvidenceUpload });

					log('Evidence uploaded');
					setCurrentUser(user);
					hideIsLoading();
					history.push('/main' + location.search);
				})
				.catch(e => {
					appInsights.trackEvent({ name: AppInsEvents.EvidenceUploadError });
					lerror(e);
					hideIsLoading();
					appInsights.trackException({ exception: new Error(e) });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [image]);

	const upserEvidence = (photoData: string, imagetype: string, ftype: string) => {
		if (imagetype && currentUser && currentUser.mobile) {
			const phDataB64: string = photoData.substr(photoData.indexOf(',') + 1);
			const evidenceReq: HDFUserEvidenceRequest = {
				mobile: currentUser.mobile,
				file: phDataB64,
				type: imagetype as EvidenceType,
				filetype: ftype.toLowerCase() === pdfMimeType ? "pdf" as EvidenceFileType : "image" as EvidenceFileType
			};
			hdfService
				.upsertEvidence(evidenceReq)
				.then((user: HDFUser) => {
					setCurrentUser(user);
					if (currentImageType) setCurrentImageType('');
					hideIsLoading();
				})
				.catch(e => {
					lerror(e);
					if (currentImageType) setCurrentImageType('');
					hideIsLoading();
					appInsights.trackException({ exception: new Error(e) });
				});
		}
	};

	useEffect(() => {
		if (files) {
			showIsLoading();
			const file = files.files[0];
			const fileType = file.type;

			log('File selected');
			loadFile(file)
				.then(file => {
					upserEvidence(file, files.fileType, fileType);
				})
				.catch(error => {
					lerror(error);
					appInsights.trackException({ exception: new Error(error) });
				});
		}

		if (errors.hasInvalidImage || errors.hasInvalidFileSize) {
			lerror('Error with the images');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	const disaplyVaccinationEvidence = (double: boolean, isVICState: boolean) => {
		let available: boolean ;
		if(isVICState)
		{
			available= currentUser!.evidences.findIndex(e => e.type.toString() === 'booster_vaccination') > -1;
		}
		else{
			available= currentUser!.evidences.findIndex(e => e.type.toString() === 'vaccination') > -1;
		}
		//let available: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'vaccination') > -1;

		if (double) {
			available = currentUser!.evidences.findIndex(e => e.type.toString() === 'double_vaccination') > -1;
		}
		
		

		return (
			<Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }} grow verticalAlign="center">
				<Stack horizontal={false} verticalFill verticalAlign="center">
					<FontIcon
						aria-label={available ? 'complete' : 'error'}
						iconName={available ? 'CompletedSolid' : 'StatusErrorFull'}
						style={{
							color: available ? 'green' : 'red',
							lineHeight: '32px'
						}}
					/>
				</Stack>

				<Label
					styles={{
						root: {
							...theme.fonts.medium,
							minWidth: 180
						}
					}}>
					{isVICState
						? 'You may upload evidence of your vaccination status here.'
						: 'You are required to produce evidence of your vaccination status upon request. You can also upload your evidence here.'}
				</Label>
				<PrimaryButton
					disabled={isLoading}
					onClick={() => { isVICState?
						openFileSelector(`${double ? 'double_' : 'booster_'}vaccination`)
						:openFileSelector(`${double ? 'double_' : ''}vaccination`);
					}}>
					{available ? 'Update' : 'Upload'}
				</PrimaryButton>
				
			</Stack>
		);
	};

	const disaplyExemptionEvidence = (isVICState: boolean) => {
		const available: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'exemption') > -1;
		return (
			<Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }} grow verticalAlign="center">
				<Stack horizontal={false} verticalFill verticalAlign="center">
					<FontIcon
						aria-label={available ? 'complete' : 'error'}
						iconName={available ? 'CompletedSolid' : 'StatusErrorFull'}
						style={{
							color: available ? 'green' : 'red',
							lineHeight: '32px'
						}}
					/>
				</Stack>

				<Label
					styles={{
						root: {
							...theme.fonts.medium,
							minWidth: 180
						}
					}}>
					{isVICState
						? 'You may upload evidence of your medical exemption here.'
						: 'You are required to produce evidence of your medical exemption upon request. You can also upload your evidence here.'}
				</Label>
				<PrimaryButton
					disabled={isLoading}
					onClick={() => {
						openFileSelector('exemption');
					}}>
					{available ? 'Update' : 'Upload'}
				</PrimaryButton>
			</Stack>
		);
	};

	const disaplyTestResult = (isVICState: boolean) => {
		const available: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'testresult') > -1;
		return (
			<Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }} grow verticalAlign="center">
				<Stack horizontal={false} verticalFill verticalAlign="center">
					<FontIcon
						aria-label={available ? 'complete' : 'error'}
						iconName={available ? 'CompletedSolid' : 'StatusErrorFull'}
						style={{
							color: available ? 'green' : 'red',
							lineHeight: '32px'
						}}
					/>
				</Stack>

				<Label
					styles={{
						root: {
							...theme.fonts.medium,
							minWidth: 180
						}
					}}>
					{isVICState
						? 'You may upload evidence of a negative test result here.'
						: 'You are required to produce evidence of a negative test result upon request. You can also upload your evidence here.'}
				</Label>
				<PrimaryButton
					disabled={isLoading}
					onClick={() => {
						openFileSelector('testresult');
					}}>
					{available ? 'Update' : 'Upload'}
				</PrimaryButton>
			</Stack>
		);
	};

	const submitTracerCheckIn = (values: CovidForm): void => {
		appInsights.trackEvent({ name: AppInsEvents.TracerFormSubmit });

		log('%O', values);
		hdfService
			.saveFormDate(values)
			.then(() => {
				appInsights.trackEvent({ name: AppInsEvents.TracerFormSubmitted });
				setState({
					...state,
					submittedData: values
				});
				//hideIsFormLoading();
				setIsFormLoading(false);
			})
			.catch(error => {
				lerror(error);
				appInsights.trackException({ exception: new Error(error) });
			});
	};

	const submiteForm = (values: CovidForm, helper: FormikHelpers<CovidForm>): void => {
		appInsights.trackEvent({ name: AppInsEvents.FormSubmit });

		const endDate = new Date();
		values.submissionTime = (endDate.getTime() - values.date.getTime()) / 1000;

		Fingerprint2.get({}, (components: Fingerprint2.Component[]) => {
			const fpvalues: Fingerprint2.Component[] = components.map((component: Fingerprint2.Component) => {
				return component.value;
			});

			const murmur: string = Fingerprint2.x64hash128(fpvalues.join(''), 31);
			log('Fingerprint:%s', murmur);
			values.deviceId = murmur;

			if (!values.location) {
				values.location = 'none';
			}

			if (values.staffType !== 'Other') {
				values.employer = 'none';
			}

			if (state.contactedByGov && state.contactedByGov.length > 0 && !values.contactedByGov) {
				values.contactedByGov = false;
			}
			values.company = 'cpb';

			const cacheObj = {
				firstname: values.firstname,
				lastname: values.lastname,
				mobile: values.mobile,
				email: values.email,
				project: values.project,
				location: values.location,
				staffType: values.staffType,
				employer: values.employer,
				projectVisited: values.projectVisited,
				vaccinationStatus: values.vaccinationStatus
			};
			lscache.set(cacheKey, cacheObj, process.env.REACT_APP_CACHEEXPMINS);
			if (isPersonAllowed(values)) {
				values.result = 'granted';
			}

			if (values.project) {
				const contacts = getContactsForProject(values.project);
				values.projectMobiles = contacts.mobiles;
				values.projectEmail = contacts.email;

			}
			if (values.location && values.project) {
				const contacts = getContactsForSite(values.project, values.location);

				values.siteMobiles = contacts.mobiles;
				values.siteEmail = contacts.email;

			}

			log('%O', values);
			hdfService
				.saveFormDate(values)
				.then(() => {
					appInsights.trackEvent({ name: AppInsEvents.FormSubmitted });

					const selectedProject = state.allSites.find(s => s.project === values.project);
					const projectState = selectedProject?.state ?? 'nsw';

					let cpbNswVaccWarn: boolean = false;
					let cpbVicVaccWarn: boolean = values.residentInNSWHODA;

					if (values.residentInNSWHODA) {
						if (values.vaccinationStatus === 'single') {
							cpbNswVaccWarn = true;
						}
					}

					if (values.vaccinationStatus === 'exemption') {
						cpbVicVaccWarn = true;
					}

					const cpbVacWarning: boolean = projectState.toLowerCase() === 'vic' ? cpbVicVaccWarn : cpbNswVaccWarn;

					setState({
						...state,
						allowed: isPersonAllowed(values),
						warning: values.inContactTested || values.visitedHotspot || values.rapidAntigenTesting === 'INVALID' || cpbVacWarning,
						confirmed: true,
						submittedData: values,
						isAffectedWorkerNo: values.affectedWorker === 'no',
						rapidTestResult: values.rapidAntigenTesting
					});
				})
				.catch(error => {
					lerror(error);
					appInsights.trackException({ exception: new Error(error) });
				});
		});
	};

	const getSitesForProject = (project?: string): IDropdownOption[] => {
		let _ret: IDropdownOption[] = [];
		const selectProject = state.allSites.find((site: COVIDFormLookupSite) => {
			return site.project === project;
		});
		selectProject?.locations.forEach(val => {
			_ret.push({ key: val.name, text: val.name });
		});

		return _ret;
	};


	const getContactsForProject = (project: string): any => {
		let _ret: any = { mobiles: [], email: [] };

		const projectMobNumbers: string[] | undefined = state.allSites.find(p => p.project === project)?.mobilenumbers;
		const projectEmail: string[] | undefined = state.allSites.find(p => p.project === project)?.email;

		if (projectMobNumbers && projectMobNumbers.length > 0) _ret['mobiles'] = projectMobNumbers;
		if (projectEmail && projectEmail.length > 0) _ret['email'] = projectEmail;

		if ((!projectMobNumbers || projectMobNumbers.length < 1) && state.defaultSMSNumber && state.defaultSMSNumber.length > 0) {
			_ret['mobiles'] = state.defaultSMSNumber;
		}

		return _ret;
	};

	const getContactsForSite = (project: string, site: string): any => {
		let _ret: any = { mobiles: [], email: [] };

		const siteMobNumbers: string[] | undefined = state.allSites.find(p => p.project === project)?.locations.find(s => s.name === site)?.mobilenumbers;
		const siteEmail: string[] | undefined = state.allSites.find(p => p.project === project)?.locations.find(s => s.name === site)?.email;

		if (siteMobNumbers && siteMobNumbers.length > 0) _ret['mobiles'] = siteMobNumbers;
		if (siteEmail && siteEmail.length > 0) _ret['email'] = siteEmail;

		return _ret;
	};

	const renderFinalCheck = (): JSX.Element => {
		const person: CovidForm = state.submittedData || new CovidForm();
		return (
			<div className="ms-Grid" dir="ltr" ref={confRef}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-lg12">
						<Stack tokens={{ childrenGap: 8 }} key="CovidFormTitle" verticalFill={true} verticalAlign="center">
							{state.warning && !state.isRAT ? (
								<Label
									styles={{
										root: {
											fontWeight: 'bold'
										}
									}}>
									As you have been in hotspot area, please talk with your supervisor before entering site.
								</Label>
							) : (
								<Label>{state.showScreenMessage}</Label>
							)}
							<Stack horizontalAlign="center">
								{state.allowed ? (
									state.warning ? (
										<img alt="warning" className="confirmationImg" src="/imgs/warning.png" />
									) : (
										<img alt="granted" className="confirmationImg" src="/imgs/granted.png" />
									)
								) : (
									<img alt="denied" className="confirmationImg" src="/imgs/denied.png" />
								)}
								<div className="confirmationDateBox">
									<div className="confirmationDateDayMonth">{moment(person.date).format('D')}</div>
									<div className="confirmationDateMonth">{moment(person.date).format('MMMM')}</div>
									<div className="confirmationDateDayWeek">{moment(person.date).format('dddd')}</div>
									<div className="confirmationDateTime">{moment(person.date).format('LT')}</div>
								</div>
							</Stack>
							<Stack horizontalAlign="start">
								<Label styles={confirmationPersonInfoStyle}>
									<strong>Name:</strong> {person.firstname + ' ' + person.lastname}
								</Label>
								<Label styles={confirmationPersonInfoStyle}>
									<strong>Project:</strong> {person.project}
								</Label>
								{person.location && person.location !== 'none' && (
									<Label styles={confirmationPersonInfoStyle}>
										<strong>Site:</strong> {person.location}
									</Label>
								)}
								{state.isRAT ? (
									<>
										{state.allowed ? (
											state.warning ? (
												<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
													Your test was invalid. You must Re-Test.
												</Label>
											) : (
												<Label styles={confirmationPersonInfoStyle}>Has undertaken a Rapid Antigen Test and the result was NEGATIVE</Label>
											)
										) : (
											<>
												{!state.isAffectedWorkerNo ? (
													<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
														Has undertaken a Rapid Antigen Test and the result was POSITIVE
													</Label>
												) : (
													<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
														{affectedWorkedWorningLabel}
													</Label>
												)}
											</>
										)}
									</>
								) : (
									<>
										<Label styles={confirmationPersonInfoStyle}>Mandatory health declaration form completed.</Label>
										{state.allowed && !state.submittedData?.hasSymptoms ? (
											!state.warning ?   (
												<div>
													<Label>No flu symptoms declared.</Label>
													<Label>Please proceed and enter site/office.</Label>
												</div>
											) : (
												<>
													{state.submittedData?.vaccinationStatus === 'single' && state.submittedData?.vaccFirstDoseDate! < threeWeeksAgo && (
														<Label>May need to provide evidence of vaccination.</Label>
													)}
													{state.submittedData?.vaccinationStatus === 'single' && state.submittedData?.vaccFirstDoseDate! > threeWeeksAgo && (
														<Label>Provide evidence of negative result.</Label>
													)}
													{state.submittedData?.vaccinationStatus === 'single' && <Label>Please proceed and enter site/office.</Label>}
												</>
											)
										) : (											
											<Label style={{ color: 'red' }} styles={confirmationPersonInfoStyle}>
												{(state.submittedData?.hasSymptoms === true
													|| state.submittedData?.travel === true
													|| state.submittedData?.inContactTested === true
													|| state.submittedData?.diagnosed === true
													|| state.submittedData?.quarantineOrder === true
													|| state.submittedData?.inContactWithDiagnosed === true
													|| state.submittedData?.contactedByGov === true
													|| state.submittedData?.residentInNSWHODA === true
													|| state.submittedData?.permitByServiceNSW === true
												)
													? <ul style={{ paddingInlineStart: '20px' }}>
														<li>You are not allowed to sign on or start work.</li>

														{/*state.submittedData?.vaccinationStatus === 'exemption' ? (
												   <li>Claimed exemption from vaccination</li>
											   ) : (*/}
														<li>Contact a health care professional for advice.</li>
														{/*)*/}
														<li>Speak to your employer about your working arrangements.</li>
													</ul> : state.submittedData?.vaccinationStatus !== 'double'? <div>
														<ul style={{ paddingInlineStart: '20px' }}>
															<li>
																If you are unvaccinated or partially vaccinated, you must not attend the office and should continue to work from home.<br />
																If it is not practical to do so, please speak with your General Manager.</li>
														</ul>
													</div> : ''


												}
											</Label>
										)}
									</>
								)}
							</Stack>
							{isLoading && <ProgressIndicator styles={{ root: { width: '100%' } }} />}
							{state.isRAT && state.allowed && !state.warning && (
								<PrimaryButton data-html2canvas-ignore styles={buttonStyle} disabled={isLoading} onClick={getImage}>
									Save as Evidence
								</PrimaryButton>
							)}
							<PrimaryButton
								data-html2canvas-ignore
								styles={buttonStyle}
								disabled={isLoading}
								onClick={() => {
									history.push('/main');
								}}>
								Home
							</PrimaryButton>
						</Stack>
					</div>
				</div>
			</div>
		);
	};

	const renderTrackerCheck = (): JSX.Element => {
		const person: CovidForm = state.submittedData || new CovidForm();
		return (
			<div className="ms-Grid" dir="ltr" ref={confRef}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-lg12">
						<Stack tokens={{ childrenGap: 8 }} key="CovidFormTitle" verticalFill={true} verticalAlign="center">
							<Label>{state.showScreenMessage}</Label>

							<Stack horizontalAlign="center">
								<img alt="granted" className="confirmationImg" src="/imgs/granted.png" />
								<div className="confirmationDateBox">
									<div className="confirmationDateDayMonth">{moment(person.date).format('D')}</div>
									<div className="confirmationDateMonth">{moment(person.date).format('MMMM')}</div>
									<div className="confirmationDateDayWeek">{moment(person.date).format('dddd')}</div>
									<div className="confirmationDateTime">{moment(person.date).format('LT')}</div>
								</div>
							</Stack>
							<Stack horizontalAlign="start">
								<Label styles={confirmationPersonInfoStyle}>
									<strong>Name:</strong> {currentUser?.firstname + ' ' + currentUser?.lastname}
								</Label>
								<Label styles={confirmationPersonInfoStyle}>
									<strong>Project:</strong> {person.project}
								</Label>
								{person.location && person.location !== 'none' && (
									<Label styles={confirmationPersonInfoStyle}>
										<strong>Site:</strong> {person.location}
									</Label>
								)}

								<Label>Please proceed and enter site/office.</Label>
							</Stack>
							<PrimaryButton
								data-html2canvas-ignore
								styles={buttonStyle}
								disabled={isLoading}
								onClick={() => {
									history.push('/main');
								}}>
								Home
							</PrimaryButton>
						</Stack>
					</div>
				</div>
			</div>
		);
	};

	const isPersonAllowed = (values: CovidForm): boolean => {
		if (state.isRAT) {
			if (values.rapidAntigenTesting === 'NEGATIVE' || values.rapidAntigenTesting === 'INVALID') {
				return true;
			} else {
				return false;
			}
		} else {

			let extraFields: boolean = !values.diagnosed && !values.quarantineOrder;
			const selectedProject = state.allSites.find(s => s.project === values.project);


			let projectState = selectedProject?.state ?? 'nsw';
			if (selectedProject && values.location) {
				const site = selectedProject.locations.find(l => l.name === values.location);
				if (site?.state) projectState = site?.state;
			}


			if (projectState?.toString().toLowerCase() === 'nsw' && values.vaccinationStatus !== 'double') { return false; }



			let cpbNswVacc: boolean = false;
			let cpbNswVaccWarn: boolean = false;
			let cpbVICVacc: boolean = false;
			let cpbVICVaccWarn: boolean = false;
			if (values.residentInNSWHODA) {
				if (values.vaccinationStatus === 'none') {
					//allowed
					log(`Not vaccinated.`);
					cpbNswVacc = false;
					cpbVICVacc = false;
				} else if (values.vaccinationStatus === 'single' && values.vaccFirstDoseDate! < threeWeeksAgo) {
					log(`single vaccination done more than 3 weeks ago, warning!`);
					cpbNswVacc = true;
					cpbNswVaccWarn = true;
					cpbVICVacc = true;
				} else if (values.vaccinationStatus === 'single' && values.vaccFirstDoseDate! > threeWeeksAgo) {
					//allowed
					log(`single vaccination done more than 3 weeks ago, all good!`);
					cpbNswVaccWarn = true;
					cpbNswVacc = true;
					cpbVICVacc = true;
				} else if (values.vaccinationStatus === 'exemption') {
					//denied -> ERROR
					log(`exempted from vaccination not allowed.`);

					cpbNswVacc = false;
					cpbVICVaccWarn = true;
					cpbVICVacc = true;
				} else if (values.vaccinationStatus === 'double') {
					//allowed
					log(`Double vaccinated, all good to go.`);

					cpbNswVacc = true;
					cpbVICVacc = true;
				}
				

				cpbNswVacc = cpbNswVacc && values.permitByServiceNSW;
				cpbVICVacc = cpbVICVacc && values.permitByServiceNSW;
			} else {
				cpbNswVacc = true;
				cpbVICVacc = true;
			}

			const cpbVac: boolean = projectState?.toLowerCase() === 'vic' ? cpbVICVacc : cpbNswVacc;
			
			 if (projectState?.toString().toLowerCase() === 'vic' && (values.vaccinationStatus !== 'booster' && values.vaccinationStatus !== 'double')) 
			{ 
				return false; 			
			}


			if (
				!values.hasSymptoms &&
				!values.inContactWithDiagnosed &&
				!values.travel &&
				(!state.contactedByGov || state.contactedByGov.length < 1 || !values.contactedByGov) &&
				values.affectedWorker !== 'no' &&
				extraFields &&
				cpbVac
			) {
				log('Person Allowed!');
				if (values.inContactTested || values.visitedHotspot || cpbNswVaccWarn || cpbVICVaccWarn) {
					log('Allowed with Warning');
				}
				return true;
			}
			log('Person Denied!');
		}
		return false;
	};

	return state.confirmed ? (
		renderFinalCheck()
	) : isFormLoading ? (
		<LoadingShimmer />
	) : tracerCheckIn ? (
		renderTrackerCheck()
	) : (
		<>
			<Formik
				initialValues={initialForm}
				onSubmit={submiteForm}
				validationSchema={schema}
				validateOnChange={true}
				validateOnBlur={false}
				validateOnMount={true}
				render={(formProps: FormikProps<CovidForm>) => {
					log('Validation Errors: %o', formProps.errors);

					const selectedProject = state.allSites.find(s => s.project === formProps.values.project);
					//const projectState = selectedProject?.state ?? 'nsw';

					let projectState = selectedProject?.state ?? 'nsw';
					if (selectedProject && formProps.values.location) {
						const site = selectedProject.locations.find(l => l.name === formProps.values.location);
						if (site?.state) projectState = site?.state;
					}


					let isGreaterSydneyArea: boolean = selectedProject && selectedProject.greaterSydneyArea ? true : false;
					if (selectedProject && formProps.values.location) {
						const site = selectedProject.locations.find(l => l.name === formProps.values.location);
						if (site && site.greaterSydneyArea) {
							isGreaterSydneyArea = site.greaterSydneyArea;
						}
					}

					const sitesOptions = getSitesForProject(formProps.values.project);
					const showHotspotQuestion: boolean = state.allSites.find(p => p.project === formProps.values.project)?.showHotspotQuestion || false;



					const showSite = sitesOptions.length > 0;
					if (!showSite && formProps.values.location !== 'none') {
						log('Setting location to none.');
						formProps.setFieldValue('location', 'none', false);
					} else if (showSite && (sitesOptions.length === 1 || rapidAntigenTestingUrl) && formProps.values.location !== sitesOptions[0].key) {
						formProps.setFieldValue('location', sitesOptions[0].key, false);
						formProps.setFieldTouched('location', true, false);
					} else if (showSite && formProps.values.location === 'none') {
						log('Setting location to undefined.');
						formProps.setFieldValue('location', undefined, true);
						formProps.setFieldTouched('location', true, true);
						formProps.setFieldError('location', 'Site is required');
					} else if (showSite && !formProps.values.location && !formProps.errors.location) {
						log('Trigger location validation');
						formProps.setFieldError('location', 'Site is required');
					}

					if (!state.isRAT && isGreaterSydneyArea && formProps.values.vaccinationStatus !== 'single' && formProps.values.vaccFirstDoseDate) {
						formProps.setFieldValue('vaccFirstDoseDate', undefined, true);
						formProps.setFieldTouched('vaccFirstDoseDate', true, true);
					}

					const exAvailable: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'exemption') > -1;
					const vaccAvailable: boolean = currentUser!.evidences.findIndex(e => e.type.toString().endsWith('vaccination')) > -1;
					const testAvailable: boolean = currentUser!.evidences.findIndex(e => e.type.toString() === 'testresult') > -1;

					if (vaccAvailable && !formProps.values.vaccinationEvidence) {
						formProps.setFieldValue('vaccinationEvidence', true, !formProps.values.vaccinationEvidence);
					}

					if (exAvailable && !formProps.values.exemptionEvidence) {
						formProps.setFieldValue('exemptionEvidence', true, !formProps.values.exemptionEvidence);
					}

					if (testAvailable && !formProps.values.testresultEvidence) {
						formProps.setFieldValue('testresultEvidence', true, !formProps.values.testresultEvidence);
					}

					return (
						<>
							<Form onSubmit={formProps.handleSubmit}>
								<div className="ms-Grid" dir="ltr">
									<div className="ms-Grid-row">
										<div className="ms-Grid-col ms-lg12">
											<Stack tokens={{ childrenGap: 8 }} key="CovidFormTitle" verticalFill={true} verticalAlign="center">
												<Label>Please complete this online form everyday</Label>
											</Stack>
											<Stack tokens={{ childrenGap: 12 }} key="CovidForm">
												<Field name="project">
													{field => <FormikDropdown {...field} disabled={formProps.isSubmitting || state.isRAT} label="Project" required={true} options={state.projects} />}
												</Field>
												{showSite && (
													<Field name="location">
														{field => <FormikDropdown {...field} disabled={formProps.isSubmitting || state.isRAT} label="Site" required={true} options={sitesOptions} />}
													</Field>
												)}
												{state.isRAT && (
													<>
														<Field name="staffType">{field => <FormikDropdown {...field} disabled label="Staff Type" options={state.staffType} />}</Field>
														{(formProps.values.staffType === 'Other' || formProps.values.staffType === 'Sub Contractor') && (
															<Field name="employer">{field => <FormikTextField {...field} disabled label="Employer Name" />}</Field>
														)}
														<Field name="firstname">{field => <FormikTextField {...field} disabled label="First Name" />}</Field>
														<Field name="lastname">{field => <FormikTextField {...field} disabled label="Last Name" />}</Field>
														<Field name="email">{field => <FormikTextField {...field} disabled label="Email" />}</Field>

														<Field name="mobile">
															{field => (
																<FormikTextField
																	{...field}
																	disabled
																	label="Mobile Number"
																	placeholder="Please enter a valid mobile number"
																	onGetErrorMessage={_getErrorMessageForMobile}
																/>
															)}
														</Field>
													</>
												)}
												{!state.isRAT && (
													<>
														<Field name="hasSymptoms">
															{field => (
																<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestionForState(projectState, 'hasSymptoms')} />
															)}
														</Field>
														{projectState.toLowerCase() !== 'vic' && (
															<Stack tokens={{ childrenGap: 5 }} key="CovidForm">
																<Label>Which Symptom?</Label>
																<Field name="symptoms.fever">
																	{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Fever" />}
																</Field>
																<Field name="symptoms.cough">
																	{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Cough" />}
																</Field>
																<Field name="symptoms.soreThroat">
																	{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Sore throat" />}
																</Field>
																<Field name="symptoms.shortnessOfBreath">
																	{field => <FormikCheckbox {...field} disabled={formProps.isSubmitting || !formProps.values.hasSymptoms} label="Shortness of breath" />}
																</Field>
															</Stack>
														)}
														{projectState.toLowerCase() !== 'vic' && (
															<Field name="inContactTested">
																{field => (
																	<FormikToggle
																		{...field}
																		disabled={formProps.isSubmitting}
																		onText="Yes"
																		offText="No"
																		label={getQuestionForState(projectState, 'inContactTested')}
																	/>
																)}
															</Field>
														)}
														{projectState.toLowerCase() !== 'vic' && (
														<Field name="inContactWithDiagnosed">
															{field => (
																<FormikToggle
																	{...field}
																	disabled={formProps.isSubmitting}
																	onText="Yes"
																	offText="No"
																	label={getQuestionForState(projectState, 'inContactWithDiagnosed')}
																/>
															)}
														</Field>
														)}
														{projectState.toLowerCase() !== 'vic' && (
															<Field name="diagnosed">
																{field => (
																	<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestionForState(projectState, 'diagnosed')} />
																)}
															</Field>
														)}

														<Field name="quarantineOrder">
															{field => (
																<FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={getQuestionForState(projectState, 'quarantineOrder')} />
															)}
														</Field>

														{state.contactedByGov && state.contactedByGov.length > 0 && (
															<Field name="contactedByGov">
																{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label={state.contactedByGov} />}
															</Field>
														)}
														{(state.hotspotQuestion || state.hotspotQuestionDefault)
															//&& !isGreaterSydneyArea 
															&& projectState.toLowerCase() !== 'vic' 
															&& (
																<Field name="visitedHotspot">
																	{field => (
																		<FormikToggle
																			{...field}
																			disabled={formProps.isSubmitting}
																			onText="Yes"
																			offText="No"
																			label={showHotspotQuestion ? state.hotspotQuestion : state.hotspotQuestionDefault}
																		/>
																	)}
																</Field>
															)}
														{(isGreaterSydneyArea || projectState.toLowerCase() === 'vic') && (
															<>
																{projectState.toLowerCase() !== 'vic' && (
																	<Field name="projectVisited">
																		{field => (
																			<FormikTextField
																				{...field}
																				disabled={formProps.isSubmitting}
																				label="Construction sites visited in the last 24 hours"
																				placeholder="Please enter all the construction sites you visited in the last 24 hours"
																				multiline
																			/>
																		)}
																	</Field>
																)}
																{(projectState.toLowerCase() !== 'nsw' && projectState.toLowerCase() !== 'vic')&& (
																	<Field name="affectedWorker">
																		{field => <FormikChoiceGroup {...field} defaultSelectedKey="na" options={affectedWorkerOption} label={affectedWorkedLabel} required={true} />}
																	</Field>
																)} 																																
                                                                {(projectState.toLowerCase() !== 'nsw' && projectState.toLowerCase() !== 'vic') && (
																<Field name="residentInNSWHODA">
																	{field => (
																		<FormikToggle
																			{...field}
																			disabled={formProps.isSubmitting}
																			onText="Yes"
																			offText="No"
																			label={getQuestionForState(projectState, 'residentInNSWHODA')}
																		/>
																	)}
																</Field>)}
																{projectState.toLowerCase() !== 'nsw' && projectState.toLowerCase() !== 'vic' &&(
																<Field name="permitByServiceNSW">
																	{field => <FormikToggle {...field} disabled={formProps.isSubmitting} onText="Yes" offText="No" label="Do you hold a travel permit?" />}
																</Field>)}
																<Label styles={{ root: { fontSize: 20 } }}>Vaccination Status</Label>
																<Field name="vaccinationStatus">
																	{field => (
																		<FormikChoiceGroup
																			{...field}
																			options={
																				projectState.toLowerCase() === 'vic'
																					? vaccOptionsVIC
																					: formProps.values.residentInNSWHODA
																						? vaccOptionsNSW
																						: [...vaccOptionsNSW, { key: 'noanswer', text: 'I choose not to answer' }]
																			}
																			label="Please declare your current vaccination status by answering the question below:"
																			required={true}
																		/>
																	)}
																</Field>
																{formProps.values.vaccinationStatus && formProps.values.vaccinationStatus === 'single' && formProps.values.residentInNSWHODA && (
																	<Field name="vaccFirstDoseDate">
																		{field => <FormikDatePicker {...field} label="Date Administered" required={true} firstDayOfWeek={DayOfWeek.Monday} />}
																	</Field>
																)}
																{formProps.values.vaccinationStatus &&
																	((formProps.values.vaccinationStatus === 'single' && moment(formProps.values.vaccFirstDoseDate).isAfter(moment().subtract(21, 'days'))) ||
																		formProps.values.vaccinationStatus === 'exemption') &&
																	formProps.values.vaccFirstDoseDate &&
																	disaplyTestResult(projectState.toLowerCase() === 'vic')}
																{formProps.values.vaccinationStatus &&
																	(formProps.values.vaccinationStatus === 'double' || formProps.values.vaccinationStatus === 'booster'|| formProps.values.vaccinationStatus === 'single') &&
																	disaplyVaccinationEvidence(formProps.values.vaccinationStatus === 'double', projectState.toLowerCase() === 'vic')}

																{formProps.values.vaccinationStatus &&
																	formProps.values.vaccinationStatus === 'exemption' &&
																	disaplyExemptionEvidence(projectState.toLowerCase() === 'vic')}
																{isLoading && <ProgressIndicator styles={{ root: { width: '100%' } }} />}
															</>
														)}
													</>
												)}
												<Field name="declaration">
													{field => (
														<FormikCheckbox
															styles={checkBoxAgreement}
															{...field}
															disabled={formProps.isSubmitting}
															label="I declare that the information I have supplied is true."
														/>
													)}
												</Field>
												<Field name="privacy">
													{field => (
														<FormikCheckbox
															{...field}
															disabled={formProps.isSubmitting}
															onRenderLabel={() => {
																return <span style={{ color: 'rgb(50, 49, 48)', marginLeft: '4px' }} dangerouslySetInnerHTML={{ __html: state.privacyStat || '' }}></span>;
															}}
														/>
													)}
												</Field>
											</Stack>
											<Stack tokens={{ childrenGap: 8 }}>
												<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_reCAPTCHA_SITEKEY}>
													<GoogleReCaptcha
														onVerify={token => {
															formProps.setFieldValue('reCaptchaRating', token, true);
														}}
													/>
												</GoogleReCaptchaProvider>

												<Stack tokens={{ childrenGap: 8 }} horizontal style={{ marginTop: '1em', marginBottom: '1em' }} horizontalAlign="center">
													<PrimaryButton type="submit" styles={buttonStyle} disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}>
														Submit
													</PrimaryButton>

													<DefaultButton
														type="button"
														styles={buttonStyle}
														onClick={() => {
															formProps.resetForm();
															history.push('/main');
														}}
														disabled={formProps.isSubmitting}>
														Cancel
													</DefaultButton>
												</Stack>
											</Stack>
										</div>
									</div>
								</div>
							</Form>
						</>
					);
				}}
			/>
			<HiddenFileInput accept={inputFileType} multiple={false} />
		</>
	);
};

export default HealthForm;
