import { useId } from '@fluentui/react-hooks';
import { IDialogContentProps, DialogType, Dialog, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import React, { useMemo } from 'react';

interface AppDeleteDialogProps {
	hidden: boolean;
	text: string;
	toogleAction: () => void;
	onConfirm: () => void;
}

const AppDeleteDialog = (props: AppDeleteDialogProps) => {
	const titleAreaId = useId('id-delete-title-area');
	const subTitleAreaId = useId('id-delete-subtitle-area');

	const dialogDeleteProps: IDialogContentProps = useMemo(() => {
		return {
			type: DialogType.largeHeader,
			title: 'Delete',
			closeButtonAriaLabel: 'Cancel',
			subText: props.text
		} as IDialogContentProps;
	}, [props.text]);

	return (
		<>
			{!props.hidden && (
				<Dialog
					hidden={props.hidden}
					onDismiss={props.toogleAction}
					dialogContentProps={dialogDeleteProps}
					modalProps={{
						titleAriaId: titleAreaId,
						subtitleAriaId: subTitleAreaId,
						isBlocking: true,
						styles: { main: { maxWidth: 450 } }
					}}>
					<DialogFooter>
						<PrimaryButton
							onClick={() => {
								props.onConfirm();
								props.toogleAction();
							}}
							text="Delete"
						/>
						<DefaultButton onClick={props.toogleAction} text="Cancel" />
					</DialogFooter>
				</Dialog>
			)}
		</>
	);
};

export default AppDeleteDialog;