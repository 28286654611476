export const isDev = (): boolean => {
	if (
		window.location.hostname === 'localhost' ||
		window.location.hostname === '127.0.0.1' ||
		window.location.hostname === '192.168.1.186' ||
		window.location.hostname === 'cpbhealthdeclaration.azurefd.net' ||
		window.location.hostname === 'cpb-covid19-form-app01.azurewebsites.net' ||
		window.location.hostname.indexOf('hdfdev.') > -1
	)
		return true;

	return false;
};

export enum AppInsEvents {
	FormSubmit = 'FormSubmit',
	FormSubmitted = 'FormSubmitted',
	TracerFormSubmit = 'TracerFormSubmit',
	TracerFormSubmitted = 'TracerFormSubmitted',
	
	ProjectsLoad = 'ProjectsLoad',
	ProjectsLoaded = 'ProjectsLoaded',

	CachedUser = 'CachedUser',
	ProjectInURL = 'ProjectInURL',
	RapidTestResultInURL = 'RapidTestResultInURL',

	UserLogin = 'UserLogin',
	UserLoginError = 'UserLoginError',

	UserSignUp = 'UserSignUp',
	EvidenceUpload = "EvidenceUpload",
	EvidenceUploadError = "EvidenceUploadError"

}

export const questionsByCompany = {
	cpb: {
		hasSymptoms: 'Do you have any of the following symptoms: Fever, Cough, Sore Throat, Shortness of Breath?',
		travel: 'Have you returned from any overseas country in the last 14 days?',
		inContactWithDiagnosed: 'In the past 14 days, have you been in contact with anyone who has COVID-19?',
		inContactTested: 'Have you been in contact with anyone who is currently being tested for COVID-19?',
		diagnosed: 'In the past 14 days, have you been diagnosed with COVID-19?',
		//quarantineOrder: 'Have you been directed by a Health Professional, to quarantine for 14 days'
		quarantineOrder: 'Have you been directed by a Health Professional, to quarantine?'
	}
};


export const questionsByState = {
	nsw: {
		hasSymptoms: 'Do you have any of the following symptoms: Fever, Cough, Sore Throat, Shortness of Breath?',
		travel: 'Have you returned from any overseas country in the last 14 days?',
		inContactWithDiagnosed: 'In the past 14 days, have you been in contact with anyone who has COVID-19?',
		inContactTested: 'Have you been in contact with anyone who is currently being tested for COVID-19?',
		diagnosed: 'In the past 14 days, have you been diagnosed with COVID-19?',
		//quarantineOrder: 'Have you been directed by a Health Professional, to quarantine for 14 days',
		quarantineOrder: 'Have you been directed by a Health Professional, to quarantine?',
		residentInNSWHODA:'Do you reside in one of the NSW Public Health Order Areas of Concern'
	},
	vic:{
		hasSymptoms: 'Do you have any COVID-19 symptoms (Fever, Cough, Sore Throat, Shortness of Breath)?',
		inContactWithDiagnosed: 'Have you been in contact with a confirmed case of COVID-19 in the preceding 14 days?',
		travel: 'Have you returned from any overseas country in the last 14 days?',
		inContactTested: 'Have you been in contact with anyone in your household who is currently being tested for coronavirus (COVID-19), or has symptoms consistent with COVID-19?',
		diagnosed: 'In the past 14 days, have you been diagnosed with COVID-19?',
		//quarantineOrder: 'Are you currently required to self-isolate or self-quarantine under Department of Health instructions?',
		quarantineOrder: 'Are you currently required to isolate in compliance with current health orders?',		
		residentInNSWHODA:'Have you been to any Department of Health nominated public exposure site or directed to either self-isolate for a 14 day period or to get tested and are awaiting a negative result?'
	}
};

export const affectedWorkedLabel: string =
	'If you are an affected worker required to undergo mandatory surveillance COVID-19 testing under the Public Health (COVID-19 Additional Restrictions for Delta Outbreak) Order 2021, please confirm that you have undertaken such testing and that you have evidence of the tests available for inspection on request.';

export const affectedWorkedWorningLabel: string =
	'You have indicated you are an affected worker and have not undergone mandatory health surveillance. You are not allowed to enter site until you have undergone mandatory surveillance and receive a negative test result.';

export const ratTitle: string = 'Rapid Antigen Test Result';
export const cacheKey = 'cpb-health-form-key';
export const userCacheKey = 'cpb-health-user-key';

 //const ausMobilePattern = /(^(04|\+614|00614)\d{8}$)|(^(\+642|00642)\d{7,9}$)/g;
export const ausMobilePattern = /(^(\+6){0,1}\d{7,15}$)/g;
export const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const mobileValidationError = 'Must be a valid mobile number';
export const emailValidationError = 'Must be a valid email address';

export 	const _getErrorMessageForMobile = (value: string): string => {
    return value.match(ausMobilePattern) || !value ? '' : mobileValidationError;
};

export 	const _getErrorMessageForEmail = (value: string): string => {
    return value.match(emailValidationRegex) || !value ? '' : emailValidationError;
};