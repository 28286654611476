import CovidForm from '../model/CovidForm';
import { COVIDFormLookup } from '../model/DBModel';
import HDFUser, { HDFUserEvidenceRequest } from '../model/HDFUser';
import { userCacheKey } from '../utils/Constants';
var CryptoJS = require('crypto-js');

const debug = require('debug');
const log = debug('hdf:service');
const axios = require('axios').default;
const lscache = require('lscache');

export default class COVIDFormsService {
	public static formNameGlb: string = 'covid19healthform';
	public static appUrlGlb: string = 'hdfdev.cpbcon.com.au';

	public saveFormDate = async (form: CovidForm): Promise<void> => {
		log('Saving health form for project %s...', form.location);
		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFFORM}`;
		const response = await axios.post(reqendpoing, form);

		log(`Form ${response.data.id} Saved!!!`);
	};

	public upsertUser = async (user: HDFUser): Promise<HDFUser> => {
		log('Saving User %s...', user.mobile);
		if (!user.id) user.id = user.mobile;

		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFUSER?.replace('{usermobile}', '')}`;
		const response = await axios.post(reqendpoing, user);

		log(`User ${response.data.mobile} Saved!!!`);
		if (lscache.get(userCacheKey)) HDFUser.SaveUserInCache(response.data as HDFUser);
		return response.data as HDFUser;
	};

	public setUserPIN = async (mobile: string, pin: string): Promise<void> => {
		log('Setting PIN for user %s...', mobile);

		const hasedPin = CryptoJS.SHA3(pin);

		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFSECUSER?.replace('{usermobile}', mobile)}`;
		await axios.post(reqendpoing, {
			password: hasedPin.toString()
		});

		log(`User ${mobile} Saved!!!`);
		return;
	};

	public getUser = async (mobile: string): Promise<HDFUser> => {
		log('Saving User %s...', mobile);
		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFUSER?.replace('{usermobile}', `/${mobile}`)}`;
		const response = await axios.get(reqendpoing);

		log(`User ${response.data.mobile} retrieved!!!`);

		return response.data as HDFUser;
	};

	public loginUser = async (mobile: string, pin: string): Promise<HDFUser|undefined> => {

		const hasedPin = CryptoJS.SHA3(pin);

		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFLOGIN?.replace('{usermobile}', mobile)}`;
		const response = await axios.post(reqendpoing, {
			password: hasedPin.toString()
		});

		if (response.data.result === true){
			const u:HDFUser = await this.getUser(mobile);

			return u;
		}else{
			return;
		}
	}

	public getConfirmationCode = async (mobile: string): Promise<number> => {
		log('Getting verification code for %s...', mobile);
		const reqendpoing = process.env.REACT_APP_REST_VERCODE;
		const response = await axios.post(reqendpoing, {
			mobile: mobile
		});

		return response.data.code as number;
	};

	public getEvidence = async (evidenceId:string) : Promise<string> => {
		log('Getting evidence %s...', evidenceId);
		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFEVIDENCE?.replace('{evidenceId}', evidenceId)}`;
		const response = await axios.get(reqendpoing);

		return response.data.file as string;
	}

	public getUserForms = async (usermobile:string) : Promise<CovidForm[]> => {
		log('Getting forms for %s...', usermobile);
		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFGETFORMS?.replace('{usermobile}', usermobile)}`;
		const response = await axios.get(reqendpoing);
		const allforms: CovidForm[] = response.data as CovidForm[];
		log("found %s forms",allforms)
		return allforms;
	}

	public upsertEvidence = async (evidenceReq:HDFUserEvidenceRequest) : Promise<HDFUser> => {
		log('Uploading evidence for %s, type %s...', evidenceReq.mobile, evidenceReq.type);
		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFEVIDENCE?.replace('/{evidenceId}', "")}`;
		const response = await axios.post(reqendpoing, evidenceReq);
		if (lscache.get(userCacheKey)) HDFUser.SaveUserInCache(response.data as HDFUser);

		return response.data as HDFUser;
	}

	public deleteEvidence = async (user:HDFUser, evidenceId:string) : Promise<HDFUser> => {
		log('Deleting evidence for %s, id %s...', user.mobile, evidenceId);
		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFEVIDENCE?.replace('{evidenceId}', evidenceId)}`;
		await axios.delete(reqendpoing);

		let newUsr:HDFUser = {
			...user,
			evidences: user.evidences.filter(e => e.id !== evidenceId)
		}

		const ret = await this.upsertUser(newUsr);
		return ret;
	}

	public getLookupForForm = async (formName?: string, appUrl?: string): Promise<COVIDFormLookup> => {
		const _form = formName || COVIDFormsService.formNameGlb;
		let _appUrl = appUrl || COVIDFormsService.appUrlGlb;
		const formConfigCacheKey = `cpb-hdf-config-${formName}-key`;

		let cachedconfig: COVIDFormLookup = lscache.get(formConfigCacheKey);

		if (cachedconfig && cachedconfig.sites && cachedconfig.staffType) return cachedconfig;

		if (_appUrl === 'localhost') {
			_appUrl = 'cpbhealthdeclaration.azurefd.net';
			//_appUrl = 'hdfapp.cpbcon.com.au';
		}
		log('Getting form lookup for Covid Form...', _form);

		let item: any;

		const reqendpoing = `${process.env.REACT_APP_REST_BASEURL}${process.env.REACT_APP_REST_HDFCONFIG}`;
		const response = await axios.post(reqendpoing, {
			appUrl: _appUrl,
			formName: _form
		});

		if (response.data) {
			item = response.data;
			log('Item retrivied: %O', item);

			if (item && item.sites && item.staffType){
				item.company = 'cpb';
				lscache.set(formConfigCacheKey, item, process.env.REACT_APP_CACHEEXPMINS);
			} 
			return item as COVIDFormLookup;
		}

		return {} as COVIDFormLookup;
	};
}
